import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TranslateModule} from '@ngx-translate/core';
import {MomentModule} from 'angular2-moment';
import {MaterialModule} from '../material/material.module';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {NguCarouselModule} from '@ngu/carousel';
import {FormModule} from '../form/form.module';
import {LoadingComponent} from '../../components/loading/loading.component';
import {DialogComponent} from '../../components/dialog/dialog.component';
import {CreateEventsDialogComponent} from '../../components/create-events-dialog/createEventsDialog.component';
import {OwlModule} from 'ngx-owl-carousel';
import {TopBarContentComponent} from '../../top-bar-content/top-bar-content.component';
import {FooterComponent} from '../../footer/footer.component';
import {RouterModule} from '@angular/router';
import {ReCaptchaModule} from 'angular2-recaptcha';
import {CardNumberPipe} from './cardNumber.pipe';
import {ShowComponent} from '../../components/show/show.component';
import {SeatChartComponent} from '../../components/seat-chart/seat-chart.component';
import { TooltipModule} from 'ngx-bootstrap';
import {ChangePasswordComponent} from '../../change-password/change-password.component';
import {ImageDialogComponent} from '../../components/image-dialog/image-dialog.component';
import {SuccessBookingComponent} from '../../components/success-booking/success-booking.component';
import {ShowDetailComponent} from '../../components/show-detail/show-detail.component';
import {ReceiptPopupComponent} from '../../components/receipt-popup/receipt-popup.component';
import {CalendarPopupComponent} from '../../components/calendar-popup/calendar-popup.component';
import {CountriesPopupComponent} from '../../components/countries-popup/countries-popup.component';
import { EditEventsDialogComponent } from 'src/app/components/edit-events-dialog/editEventsDialog.component';
import { CreateShowsDialogComponent } from 'src/app/components/create-shows-dialog/createShowsDialog.component';
import { MatDatepickerModule, MatInputModule, MatNativeDateModule } from '@angular/material';

import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker'
import { NgbModule, NgbTimepickerConfig, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { EditShowDetailsDialog } from 'src/app/components/editShowDetailsDialog/editShowDetailsDialog.component';
import { EditShowsForm } from 'src/app/components/edit-shows-form/editShowsForm.component';
import { CreateEmployeesDialogComponent } from 'src/app/components/create-employees-dialog/createEmployeesDialog.component';
import { PaginationComponent } from 'src/app/components/pagination/pagination.component';
import { ReservationsShowsDialogComponent } from 'src/app/components/reservationsShows/reservationsShows.component';
import { ReservationsDetailsDialogComponent } from 'src/app/components/reservations-details-dialog/reservations-details-dialog.component';
import { ClientReservationsDetailsDialogComponent } from 'src/app/components/client-reservations-details-dialog/client-reservations-details-dialog.component';
import { ReservationsFiltersDialogComponent } from 'src/app/components/reservations-filters-dialog/reservations-filters-dialog.component';
import { StatisticsFiltersDialogComponent } from 'src/app/components/statistics-filters-dialog/statistics-filters-dialog.component';
import { UserFooterComponent } from 'src/app/userFooter/userFooter.component';
import { AboutComponent } from 'src/app/about/about.component';
import { UserNavbarComponent } from 'src/app/userNavbar/userNavbar.component';
import { CreateZoneDialogComponent } from 'src/app/components/create-zone-dialog/createZoneDialog.component';
import { MenuBtnComponent } from 'src/app/components/menuBtn/menuBtn.component';
import { EventDashboardFooterComponent } from 'src/app/components/eventDashboardFooter/eventDashboardFooter.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { AddClientInfoDialogComponent } from 'src/app/components/addClientInfo/addClientInfo.component';
import { ExchangeDialogComponent } from 'src/app/components/exchange-dialog/exchange-dialog.component';
import { BuyTicketStepperComponent } from 'src/app/components/buyTicketStepper/buyTicketStepper.component';
import { PaymentStepComponent } from 'src/app/components/paymentStep/paymentStep.component';
import { SideBarComponent } from 'src/app/components/sideBar/sideBar.component';
import { MediaQueryScreenComponent } from 'src/app/mediaQueryScreen/mediaQueryScreen.component';
import { TicketsMediaComponent } from 'src/app/ticketsMedia/ticketsMedia.component';
import { QRCodeModule } from 'angularx-qrcode';
import { TicketsSummaryComponent } from 'src/app/components/ticketsSummary/ticketsSummary.component';
import { BackBtnVendorComponent } from 'src/app/components/backBtnVendor/backBtnVendor.component';


@NgModule({
  imports: [
    QRCodeModule,
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    MaterialModule,
    FormModule,
    MomentModule,
    OwlModule,
    FormsModule,
    RouterModule,
    ReCaptchaModule,
    TooltipModule.forRoot(), 
    MatInputModule,
    MatDatepickerModule, 
    MatNativeDateModule,
    NgxMaterialTimepickerModule,
    NgbModule,
    NgbTimepickerModule, 
    HighchartsChartModule, 
    
  ],
  declarations: [
    LoadingComponent,
    DialogComponent,
    TopBarContentComponent,
    FooterComponent,
    CardNumberPipe,
    ShowComponent,
    SeatChartComponent,
    ChangePasswordComponent,
    ImageDialogComponent,
    SuccessBookingComponent,
    CountriesPopupComponent,
    ShowDetailComponent,
    ReceiptPopupComponent,
    CalendarPopupComponent, 
    CreateEventsDialogComponent, 
    EditEventsDialogComponent, 
    CreateShowsDialogComponent,
    EditShowDetailsDialog, 
    EditShowsForm, 
    CreateEmployeesDialogComponent,
    PaginationComponent,
    ReservationsShowsDialogComponent, 
    TicketsSummaryComponent,
    ReservationsDetailsDialogComponent,
    ClientReservationsDetailsDialogComponent,
    ReservationsFiltersDialogComponent, 
    StatisticsFiltersDialogComponent,
    UserFooterComponent, 
   UserNavbarComponent,
   
    // AboutComponent
    CreateZoneDialogComponent, 
    MenuBtnComponent, 
    EventDashboardFooterComponent,
    AddClientInfoDialogComponent, 
    ExchangeDialogComponent, 
    BuyTicketStepperComponent, 
    PaymentStepComponent, 
    SideBarComponent, 
    MediaQueryScreenComponent, 
    TicketsMediaComponent, 
    BackBtnVendorComponent
  ],
  exports: [
    FlexLayoutModule,
    TranslateModule,
    MaterialModule,
    FormModule,
    LoadingComponent,
    DialogComponent,
    MomentModule,
    OwlModule,
    FormsModule,
    FormsModule,
    ReCaptchaModule,
    TopBarContentComponent,
    FooterComponent,
    RouterModule,
    ScrollingModule,
    CardNumberPipe,
    NguCarouselModule,
    ShowComponent,
    SeatChartComponent,
    TooltipModule,
    ChangePasswordComponent,
    ImageDialogComponent,
    SuccessBookingComponent,
    ShowDetailComponent,
    ReceiptPopupComponent,
    CalendarPopupComponent,
    CountriesPopupComponent, 
    CreateEventsDialogComponent, 
    EditEventsDialogComponent, 
    CreateShowsDialogComponent, 
    EditShowDetailsDialog, 
    EditShowsForm, 
    CreateEmployeesDialogComponent, 
    PaginationComponent, 
    ReservationsShowsDialogComponent,
    TicketsSummaryComponent, 
    ReservationsDetailsDialogComponent, 
    ClientReservationsDetailsDialogComponent,
    ReservationsFiltersDialogComponent, 
    StatisticsFiltersDialogComponent,
  
   UserFooterComponent, 
   UserNavbarComponent,
    // AboutComponent
    CreateZoneDialogComponent, 
    MenuBtnComponent, 
    EventDashboardFooterComponent,
    AddClientInfoDialogComponent, 
    ExchangeDialogComponent, 
    BuyTicketStepperComponent, 
    PaymentStepComponent, 
    SideBarComponent, 
    MediaQueryScreenComponent, 
    TicketsMediaComponent, 
    BackBtnVendorComponent
  ],
  entryComponents: [
    DialogComponent,
    ShowComponent,
    ChangePasswordComponent,
    ImageDialogComponent,
    SuccessBookingComponent,
    ReceiptPopupComponent,
    CalendarPopupComponent,
    CountriesPopupComponent, 
    CreateEventsDialogComponent, 
    EditEventsDialogComponent, 
    CreateShowsDialogComponent, 
    EditShowDetailsDialog, 
    EditShowsForm,
    CreateEmployeesDialogComponent, 
    PaginationComponent, 
    ReservationsShowsDialogComponent, 
    TicketsSummaryComponent,
    ReservationsDetailsDialogComponent, 
    ClientReservationsDetailsDialogComponent,
    ReservationsFiltersDialogComponent, 
    StatisticsFiltersDialogComponent,
    UserFooterComponent, 
    UserNavbarComponent,
  
    // AboutComponent
    CreateZoneDialogComponent, 
    MenuBtnComponent, 
    EventDashboardFooterComponent,
    AddClientInfoDialogComponent, 
    ExchangeDialogComponent, 
    BuyTicketStepperComponent, 
    PaymentStepComponent, 
    SideBarComponent, 
    MediaQueryScreenComponent, 
    TicketsMediaComponent, 
    BackBtnVendorComponent
  ]
})
export class SharedModule {
}
