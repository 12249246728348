import {Component, Inject, EventEmitter, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {CommunicationService} from '../../services/communication/communication.service';
import {TranslateService} from '@ngx-translate/core';

import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { environment } from 'src/environments/environment';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { EventsService } from 'src/app/events/events.service';
import { Subscription } from 'rxjs';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { IOption } from 'src/app/modules/form/select/select.interface';
import { ShowsService } from 'src/app/shows/shows.service';
import * as moment from 'moment';


@Component({
  selector: 'app-statistics-filters-dialog',
  templateUrl: './statistics-filters-dialog.component.html',
  styleUrls: ['./statistics-filters-dialog.component.scss'],
})
export class StatisticsFiltersDialogComponent {

  public currentLang;
  public direction;
  public form: FormGroup;
  public translation;
  // public emailRegex = variables.emailRegex;
  public emailPatternErrorMessage;
  public validCaptcha: boolean;
  // public countries = countries;
  public isResponsive: boolean;
  public title = 'Contact Us'
  public siteKey: string = environment.captchaSiteKey;
  public crewTypes :string[] = ['actors', 'authors', 'directors'] ; 
  public crews  = {} ; 
  public isLoading : boolean = false; 
  public isVendors : boolean = true;
  public statusList : IOption[] = [];
  public enteranceStatus : IOption[] = []; 
  public reservationsWay : IOption[] = []; 
  public currentDate = new Date(); 
  public meridian : boolean ; 
  public eventEmitter :EventEmitter<any> = new EventEmitter(); 
  public filters = {}; 
  @Output() public filtersSelected: EventEmitter<object> = new EventEmitter<object>();

  constructor(@Inject(MAT_DIALOG_DATA) public data, 
              private _formBuilder: FormBuilder,
              private _utilitiesService: UtilitiesService,
              private _showsService: ShowsService, 
              private _communicationService: CommunicationService,
              private _permissionService: PermissionService,
              private _translate: TranslateService, private _dialog: MatDialog) {


    this.isVendors = this._permissionService.checkIsUserVendor();
    console.log( "isVendors",this.isVendors)
 
  }


  public closeCreateShow() {
    this._dialog.closeAll(); 
  }


  public ngOnInit() {

    this.currentLang = this._translate.currentLang;
    this.direction = this.currentLang === 'ar' ? 'rtl' : 'ltr';
    this._translate.getTranslation(this._translate.currentLang).subscribe(translation => {
      console.log("translation events" ,translation)
      this.translation = translation;
    });
   
  }

  public dateOnChange(name,event) {
      
    this._communicationService.notifyComponent('app-reservations','getReservationsfilters',{
      [name] : moment(event.value).format('YYYY-MM-DD')
    }); 
    this._communicationService.notifyComponent('app-statistics','getStatisticsfilters',{
      [name] : moment(event.value).format('YYYY-MM-DD')
    }); 
  }
  public removeFilters () {
    this._communicationService.notifyComponent('app-statistics','getStatisticsfilters',{
      remove : true 
    }); 
 
    this.data.form.controls['startDate'].setValue(null)
    this.data.form.controls['endDate'].setValue(null); 
    this._dialog.closeAll(); 
  }
  public selectfilters (val) {
    
   
   
    this._communicationService.notifyComponent('app-reservations','getReservationsfilters',{
      [val.name] : val.value
    }); 

  }
 

}
