import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {CommunicationService} from '../../services/communication/communication.service';
import {TranslateService} from '@ngx-translate/core';

import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { environment } from 'src/environments/environment';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { EventsService } from 'src/app/events/events.service';
import { Subscription } from 'rxjs';
import { PermissionService } from 'src/app/services/permission/permission.service';

@Component({
  selector: 'app-dialog-create',
  templateUrl: './createEventsDialog.component.html',
  styleUrls: ['./createEventsDialog.component.scss']
})
export class CreateEventsDialogComponent {

  public currentLang;
  public direction;
  public form: FormGroup;
  public translation;
  // public emailRegex = variables.emailRegex;
  public emailPatternErrorMessage;
  public validCaptcha: boolean;
  // public countries = countries;
  public isResponsive: boolean;
  public title = 'Contact Us'
  public siteKey: string = environment.captchaSiteKey;
  public crewTypes :string[] = ['actors', 'authors', 'directors'] ; 
  public crews  = {} ; 
  public isLoading : boolean = false; 
  public isVendors;
  public uploadedImg  = {Image : {data : null} , Cover : { data : null}}; 

  constructor(@Inject(MAT_DIALOG_DATA) public data, 
              private _formBuilder: FormBuilder,
              private _utilitiesService: UtilitiesService,
              private _eventsService: EventsService, 
              private _communicationService: CommunicationService,
              private _permissionService: PermissionService,
              private _translate: TranslateService, private _dialog: MatDialog) {


    this.isVendors = this._permissionService.checkIsUserVendor();
    console.log( this.isVendors)
 
  }




  public confirmation(): void {
    this._communicationService.notifyComponent(this.data.notifyComponent, this.data.action, null);
  }

  public submitForm() {
    console.log(this.form)
    console.log(this.uploadedImg);
    this._communicationService.showLoading(true) 
    this._eventsService.createEvents({
      name_en : this.form.value.englishName, 
      name_ar : this.form.value.arabicName, 
      duration :  this.form.value.duration,
      description_ar : this.form.value.descArabic,
      description_en : this.form.value.descEnglish,
      image : this.uploadedImg.Image, 
      cover : this.uploadedImg.Cover, 
      actors : this.form.value.actors,
      authors : this.form.value.authors,
      directors : this.form.value.directors,

    }).subscribe(response =>{
     
      this._communicationService.showLoading(false) ; 
      this._communicationService.notifyComponent('app-events','close-dialog'); 
      this._communicationService.notifyComponent('app-events','refresh')
    })
  }

  public setMultiSelect(val) {
    console.log(this.form)
    if (val.value.length)
    {
      this.form.get(val.name).setValue(val.value); 
      this.form.get(val.name).markAsTouched();
    }
   
    else 
    this.form.get(val.name).setValue('')
  }

  public ngOnInit() {

    this.currentLang = this._translate.currentLang;
    this.direction = this.currentLang === 'ar' ? 'rtl' : 'ltr';
    this._translate.getTranslation(this._translate.currentLang).subscribe(translation => {
      console.log("translation events" ,translation)
      this.translation = translation;
    });
    this._communicationService.showLoading(true); 

    this.crewTypes.forEach(type => {

      this._eventsService.getCrews(type).subscribe(response => {
        console.log(response)
        this.crews[type] = response['map'](item => ({text : item.name , value : item._id})); 
     
        if (Object.keys(this.crews).length == 3)
         this.isLoading = true ; 
      } , () => {} , () => {
    
      })

    }); 
    this._communicationService.showLoading(false); 


    this._initializeForm(); 
  }


  public setImageData(imageData) {
    console.log(imageData)

    this.uploadedImg[imageData['fieldName']] = imageData; 
 

  }


  private _initializeForm(): void {
    this.form = this._formBuilder.group({
      englishName: ['', [Validators.required]],
      arabicName: ['', [Validators.required]],
      descEnglish: ['',  [Validators.required]],
      descArabic: ['',  [Validators.required]],
      duration: ['',  [Validators.required]],
      actors: ['', [Validators.required]], 
      authors: ['', [Validators.required]],
      directors: ['', [Validators.required]]
    });
   
  }
}
