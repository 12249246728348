import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {variables} from '../app.variables';
import {ApiService} from '../services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class sellingTicketsService{

  constructor(private _apiService: ApiService) {
  }

  public getMap(id :string) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let endPoint =  variables.sellingTickets.getMap
      let url = environment.url + endPoint;
  
      this._apiService.sendApi('get', url,
    {id}, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

  public bookSeat(id :string, dataObj) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let endPoint =  variables.sellingTickets.bookSeat + `?id=${id}`
      let url = environment.url + endPoint;
  
      this._apiService.sendApi('post', url,
      dataObj, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }
}
