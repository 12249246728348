import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {CommunicationService} from '../../services/communication/communication.service';
import {TranslateService} from '@ngx-translate/core';

import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { environment } from 'src/environments/environment';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { EventsService } from 'src/app/events/events.service';
import { Subscription } from 'rxjs';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { IOption } from 'src/app/modules/form/select/select.interface';
import { ShowsService } from 'src/app/shows/shows.service';
import { ReservationServices } from 'src/app/reservations/reservations.service';

import {saveAs} from 'file-saver'
import { ExchangeDialogComponent } from '../exchange-dialog/exchange-dialog.component';

@Component({
  selector: 'app-dialog-reservation-details',
  templateUrl: './reservations-details-dialog.component.html',
  styleUrls: ['./reservations-details-dialog.component.scss'],
})
export class ReservationsDetailsDialogComponent {

  public currentLang;
  public direction;
  public form: FormGroup;
  public translation;
  // public emailRegex = variables.emailRegex;
  public emailPatternErrorMessage;
  public validCaptcha: boolean;
  // public countries = countries;
  public isResponsive: boolean;
  public title = 'Contact Us'
  public siteKey: string = environment.captchaSiteKey;
  public crewTypes :string[] = ['actors', 'authors', 'directors'] ; 
  public crews  = {} ; 
  public isLoading : boolean = false; 
  public isVendors : boolean = true;
  public hoursOptions : IOption[] = []; 
  public minsOptions : IOption[] = []
  public currentDate = new Date(); 
  public meridian : boolean ; 

  constructor(@Inject(MAT_DIALOG_DATA) public data, 
              private _formBuilder: FormBuilder,
              private _utilitiesService: UtilitiesService,
              private _showsService: ShowsService, 
              private _ReservationServices: ReservationServices, 
              private _communicationService: CommunicationService,
              private _permissionService: PermissionService,
              private _translate: TranslateService, private _dialog: MatDialog) {


    this.isVendors = this._permissionService.checkIsUserVendor();
    console.log( "isVendors",this.isVendors)
    console.log("this.data", this.data)
 
  }



  public closeCreateShow() {
    this._dialog.closeAll(); 
  }

  public copyId() {
    
    const copyText = document.getElementById('textId')
    navigator.clipboard.writeText(copyText.innerText);
  }

  public showPdf(linkSource) {
    const fileName = 'ticket.pdf';
    const base64PDF = linkSource.split('data:application/pdf;base64,')[1];
    const binary = atob(base64PDF.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }
    const blob = new Blob([view], {type: 'application/pdf'});
    if (window.top.navigator.msSaveOrOpenBlob) {
      window.top.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }

  public downloadAndPrint(ticketID : string) {
    this._communicationService.showLoading(true)
      this._ReservationServices.downloadandprint(ticketID).subscribe(res => {
        console.log(res)
        //@ts-ignore
        this.showPdf(res.result)

        this._communicationService.showLoading(false)
      
      })
  }
  public formatDate(date,format='') {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' , hour : 'numeric', minute:'numeric'};
    const dateObj = new Date(date); 

    return dateObj.toLocaleDateString(this.currentLang ,options)
  }
 

  public ngOnInit() {

    this.currentLang = this._translate.currentLang;
    this.direction = this.currentLang === 'ar' ? 'rtl' : 'ltr';
    this._translate.getTranslation(this._translate.currentLang).subscribe(translation => {
      console.log("translation events" ,translation)
      this.translation = translation;
    });

  }

  public enterReservation(bookingId:string) {
    this._communicationService.showLoading(true); 
    this._ReservationServices.enterReservation(bookingId)
    .subscribe(res => {
      this._communicationService.showLoading(false); 
      this._communicationService.notifyComponent('app-reservations','refresh'); 
      this._dialog.closeAll(); 
    })
  }

  public cancelReservation(ticketID : string) {
    this._communicationService.showLoading(true); 
    this._ReservationServices.cancelReservation([ticketID])
    .subscribe(res => {
      this._communicationService.showLoading(false); 
      this._communicationService.notifyComponent('app-reservations','refresh'); 
      this._dialog.closeAll(); 
    })
  }

  public sendEmailReservation(ticketID:string) {
    console.log("ticketID",ticketID)
    this._communicationService.showLoading(true); 
    this._ReservationServices.sendEmail(ticketID)
    .subscribe(res => {
      this._communicationService.showLoading(false); 
      this._communicationService.notifyComponent('app-reservations','refresh'); 
      this._dialog.closeAll(); 
    })

  }

  public openExchange()
  {
    
    this._dialog.open(ExchangeDialogComponent, {
      data : {
        bookingId : this.data.reservations.bookingId, 
        eventId : this.data.eventId, 
        row : this.data.reservations.row , 
        seat : this.data.reservations.seat, 
        ticketId : this.data.reservations._id
      }
    })
  }
  public bookReservedTicket(ticketID : string, playId : string) {
    this._communicationService.showLoading(true); 
    this._ReservationServices.bookReservedTicket([ticketID],playId)
    .subscribe(res => {
      this._communicationService.showLoading(false); 
      this._communicationService.notifyComponent('app-reservations','refresh'); 
      this._dialog.closeAll(); 
    })
  }
  
}
