import {Component, HostListener, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {CommunicationService} from '../../services/communication/communication.service';
import { PermissionService } from '../../services/permission/permission.service';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {

  public isVendors;
  public subscription; 
  public subscriptionData; 
  constructor(public _router: Router, private _communicationService: CommunicationService, private _PermissionService : PermissionService) {
    this.isVendors = this._PermissionService.checkIsUserVendor(); 

    this.subscription = this._communicationService.getData().subscribe(response => {
      
      this.subscriptionData = response;
      this._subscriptionCallback();
    });
    console.log(this.isVendors)
  }


  

  public ngOnInit() {
    let currentRoute = this._router.url.split('?')[0];
    
    this._communicationService.notifyComponent('app-top-bar', 'changeRoute', {currentRoute});
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        currentRoute = this._router.url.split('?')[0];
        this._communicationService.notifyComponent('app-top-bar', 'changeRoute', {currentRoute});
      }
    });
  }

  public onActivate(component) {
    window.scroll(0, 0);
  }

  private _subscriptionCallback(): void {
    if (this.subscriptionData && typeof this.subscriptionData) {
      if (this.subscriptionData.notifyComponent === 'app-default-layout') {
        switch (this.subscriptionData.action) {
          case 'updateVendors':
            console.log("from case updateVendors")
            this.isVendors = this._PermissionService.checkIsUserVendor(); 
            break;
          default:
            break;
        }
      }
    }
  }
}
