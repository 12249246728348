import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService as canActivate} from './services/permission/auth-guard.service';
import {CanDeactivateGuardService as CanDeactivateGuard } from './services/permission/can-deactivate-guard.service';
import {DefaultLayoutComponent} from './layout/default-layout/default-layout.component';
import {variables} from './app.variables';

const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: variables.vendorsRoutes.events,
        loadChildren: './events/events.module#EventsModule',
        canActivate: [canActivate]
      },
      {
        path: variables.vendorsRoutes.eventDashboard + '/:eventId' ,
        loadChildren: './eventDashboard/eventDashboard.module#EventDashboardModule',
        canActivate: [canActivate]
      },
      {
        path: variables.vendorsRoutes.sellingTickets + '/:eventId' ,
        loadChildren: './sellingTickets/sellingTickets.module#SellingTicketsModule',
        canActivate: [canActivate]
      },
      {
        path: variables.vendorsRoutes.sellingTickets + '/:eventId'+'/:showId' ,
        loadChildren: './sellingTickets/sellingTickets.module#SellingTicketsModule',
        canActivate: [canActivate]
      },
      {
        path: variables.routes.buyTicket + '/:eventId'+'/:showId' ,
        loadChildren: './buyTicket/buyTicket.module#BuyTicketModule',
        // canActivate: [canActivate]
      },
      {
        path: variables.vendorsRoutes.zoneManagement + '/:eventId' ,
        loadChildren: './zoneManagement/zoneManagement.module#ZoneManagementModule',
        canActivate: [canActivate]
      },
      {
        path: variables.vendorsRoutes.showMap + '/:eventId' ,
        loadChildren: './showMap/showMap.module#ShowMapModule',
        canActivate: [canActivate]
      },
      {
        path: variables.vendorsRoutes.eventDetails + '/:eventId',
        loadChildren: './eventDetails/eventDetails.module#EventDetailsModule',
        canActivate: [canActivate]
      },
      {
        path: variables.vendorsRoutes.shows + '/:eventId',
        loadChildren: './shows/shows.module#ShowsModule',
        canActivate: [canActivate]
      },
      {
        path: variables.vendorsRoutes.employees + '/:eventId',
        loadChildren: './employees/employees.module#EmployeesModule',
        canActivate: [canActivate]
      },
      {
        path: variables.vendorsRoutes.payments + '/:eventId',
        loadChildren: './payments/payments.module#PaymentsModule',
        canActivate: [canActivate]
      },
      {
        path: variables.vendorsRoutes.checkIn + '/:eventId',
        loadChildren: './enterShow/enterShow.module#EnterShowModule',
        canActivate: [canActivate]
      },
      {
        path: variables.vendorsRoutes.statistics + '/:eventId',
        loadChildren: './statistics/statistics.module#StatisticsModule',
        canActivate: [canActivate]
      },
      {
        path: variables.vendorsRoutes.reservations + '/:eventId',
        loadChildren: './reservations/reservations.module#ReservationsModule',
        canActivate: [canActivate]
      },
      {
        path: variables.vendorsRoutes.eventPage + '/:eventId',
        loadChildren: './eventPage/eventPage.module#EventPageModule',
        canActivate: [canActivate]
      },
      {
        path: variables.routes.dashboard,
        // loadChildren: './dashboard/dashboard.module#DashboardModule', 
        loadChildren: './homePage/homePage.module#HomePageModule',
        canActivate: [canActivate]
      
      },
      {
        path: variables.routes.dashboard_full,
        // loadChildren: './dashboard/dashboard.module#DashboardModule',
        loadChildren: './homePage/homePage.module#HomePageModule',
        canActivate: [canActivate]
      },
      {
        path: variables.routes.authentication + '/:screen',
        loadChildren: './authentication/authentication.module#AuthenticationModule',
        canActivate: [canActivate]
      },
      {
        path: variables.routes.authentication + '/:screen/:id',
        loadChildren: './authentication/authentication.module#AuthenticationModule',
        canActivate: [canActivate]
      },
      {
        path: variables.routes.contactUs,
        loadChildren: './contact-us/contact-us.module#ContactUsModule',
        canActivate: [canActivate]
      },
      {
        path: variables.routes.faqs,
        loadChildren: './faq/faq.module#FaqModule',
        canActivate: [canActivate]
      },
      {
        path: variables.routes.terms,
        loadChildren: './terms/terms.module#TermsModule',
        canActivate: [canActivate]
      },
      {
        path: variables.routes.aboutUs,
        loadChildren: './about/about.module#AboutModule',
        canActivate: [canActivate]
      },
      {
        path: 'homepage',
        loadChildren: './homePage/homePage.module#HomePageModule',
        //canActivate: [canActivate]
      },
      
      {
        path: 'contactinfo',
        loadChildren: './contactInfo/contactInfo.module#ContactInfoModule',
        //canActivate: [canActivate]
      },
 
    
      {
        path: 'privacyPolicy',
        loadChildren: './privacyPolicy/privacyPolicy.module#PrivacyPolicyModule',
        //canActivate: [canActivate]
      },
    
      {
        path: 'about',
        loadChildren: './about/about.module#AboutModule',
        //canActivate: [canActivate]
      },
      {
        path: variables.routes.howItWorks,
        loadChildren: './terms/terms.module#TermsModule',
        canActivate: [canActivate]
      },
      {
        path: variables.routes.plays,
        loadChildren: './plays/plays.module#PlaysModule',
        canActivate: [canActivate]
      },
      {
        path: variables.routes.show + '/:screen',
        loadChildren: './show-details/show-details.module#ShowDetailsModule',
        canActivate: [canActivate]
      },
      {
        path: variables.routes.tickets ,
        loadChildren: './myTickets/myTickets.module#MyTicketsModule',
        canActivate: [canActivate]
      },
      {
        path: variables.routes.showDetails + '/:showId',
        loadChildren: './showDetails/showDetails.module#ShowDetailsModule',
        // canActivate: [canActivate]
      },
      {
        path: variables.routes.profile,
        loadChildren: './profile/profile.module#ProfileModule',
        canActivate: [canActivate]
      }
    ]
  },
  {path: '**', redirectTo: '/' + variables.routes.dashboard}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false})],
  exports: [RouterModule],
  providers: [CanDeactivateGuard]
})
export class AppRoutingModule {
}
