import {Component, OnInit} from '@angular/core';
import {CommunicationService} from '../services/communication/communication.service';

import {TranslateService} from '@ngx-translate/core';
import {DialogComponent} from '../components/dialog/dialog.component';
import {MatDialog} from '@angular/material';
import {UtilitiesService} from "../services/utilities.service";
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FormBuilder, Validators } from '@angular/forms';
import { countries } from '../modules/form/phone-number/countries';
import { ContactUsService } from '../contact-us/contact-us.service';


@Component({
  selector: 'app-userFooter',
  templateUrl: './userFooter.component.html',
  styleUrls: ['./userFooter.component.scss']
})
export class UserFooterComponent implements OnInit {
  public faqs;
  public currentLang;
  public direction;
  public translation;
  public customOptions : OwlOptions;
  public form;
  public countries = countries;
  constructor(private _communicationService: CommunicationService,
              private _utilitiesService: UtilitiesService,
              private _formBuilder: FormBuilder,
              private _contactUsService : ContactUsService,
              private _translate: TranslateService, private _dialog: MatDialog) {
    this.currentLang = this._translate.currentLang;
    this.direction = this.currentLang === 'ar' ? 'rtl' : 'ltr';
    this.customOptions = {
      loop: true,
      mouseDrag: false,
      touchDrag: false,
      margin: 40,
      pullDrag: false,
      dots: false,
      autoplay: true ,
      autoplayTimeout: 1000,
      navSpeed: 700,
      navText: ['', ''],
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: 2
        },
        740: {
          items: 3
        },
        940: {
          items: 4
        }
      },
     
    }
  
  }

  public ngOnInit() {
    this._initializeForm()
    this._translate.getTranslation(this._translate.currentLang).subscribe(translation => this.translation = translation,
      err => null, () => {
        this._utilitiesService.setPageTitle(this.translation.TOP_BAR.FAQs);
        
      });

    
  }

  public back(): void {
    history.back();
  }

  public showAlert(title: string, content: string): void {
    const dialogId = new Date();
    this._communicationService.setLatestDialogId(dialogId);
    this._dialog.open(DialogComponent, {
      data: {
        title,
        content,
        isConfirmationPopUp: false
      }
    });
  }



  private _handleErrors(error) {
    switch (error.status) {
      default:
        this.showAlert(this.translation.GENERAL_ERRORS.INTERNAL_ERROR_TITLE, this.translation.GENERAL_ERRORS.INTERNAL_ERROR_TEXT);
        break;
    }
  }

  public submitForm() {
    this._communicationService.showLoading(true);
    const formData = this.form.getRawValue();
    const params = {
      name: formData.fullName,
      email: formData.email,
      phoneNumber: '',
      message: formData.message
    };
    if (formData.customerPhoneCountryCode) {
      let chosenCountry;
      for (const item of this.countries) {
        if (item.code === formData.customerPhoneCountryCode) {
          chosenCountry = item;
          break;
        }
      }
      params.phoneNumber = chosenCountry.dial_code + formData.customerPhoneLine;
    }
    this._contactUsService.submitContactUs(params).subscribe(response => null,
      err => {
        this._handleErrors(err);
        this._communicationService.showLoading(false);
      },
      () => {
        this._communicationService.showLoading(false);
        this.showAlert(this.translation.CONTACT_US.THANK_YOU, '');
        this._initializeForm();
        // this.captcha.reset();
      });
  }

  private _initializeForm(): void {
    this.form = this._formBuilder.group({
     fullName : ['' , Validators.required] , 
     email : ['', Validators.email], 
     customerPhoneCountryCode : ['KW', Validators.required], 
     customerPhoneLine : ['',Validators.required], 
     customerPhone : [''],
     message : ['' , Validators.required]
    });
   console.log("this.form",this.form)
  }
}
