import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {CommunicationService} from '../../services/communication/communication.service';
import {TranslateService} from '@ngx-translate/core';

import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { environment } from 'src/environments/environment';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { EventsService } from 'src/app/events/events.service';
import { Subscription } from 'rxjs';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { IOption } from 'src/app/modules/form/select/select.interface';
import { ShowsService } from 'src/app/shows/shows.service';

@Component({
  selector: 'app-dialog-create-shows',
  templateUrl: './createShowsDialog.component.html',
  styleUrls: ['./createShowsDialog.component.scss'],
})
export class CreateShowsDialogComponent {

  public currentLang;
  public direction;
  public form: FormGroup;
  public translation;
  // public emailRegex = variables.emailRegex;
  public emailPatternErrorMessage;
  public validCaptcha: boolean;
  // public countries = countries;
  public isResponsive: boolean;
  public title = 'Contact Us'
  public siteKey: string = environment.captchaSiteKey;
  public crewTypes :string[] = ['actors', 'authors', 'directors'] ; 
  public crews  = {} ; 
  public isLoading : boolean = false; 
  public isVendors : boolean = true;
  public hoursOptions : IOption[] = []; 
  public minsOptions : IOption[] = []
  public currentDate = new Date(); 
  public meridian : boolean ; 

  constructor(@Inject(MAT_DIALOG_DATA) public data, 
              private _formBuilder: FormBuilder,
              private _utilitiesService: UtilitiesService,
              private _showsService: ShowsService, 
              private _communicationService: CommunicationService,
              private _permissionService: PermissionService,
              private _translate: TranslateService, private _dialog: MatDialog) {


    this.isVendors = this._permissionService.checkIsUserVendor();
    console.log( "isVendors",this.isVendors)
 
  }


  public generateHours () {

    for(let i =1;i<=12;i++)
      this.hoursOptions.push({
    text : String(i), 
    value : String(i)
    }); 

  }

  public generateMins () {

    for(let i =0;i<60;i+=5)
      this.minsOptions.push({
    text : String(i), 
    value : String(i)
    }); 

  }




  public submitForm() {


    this._communicationService.showLoading(true) ; 
    this._showsService.createShows({
      "name_ar": this.form.value.nameArabic,
      "name_en":this.form.value.nameEnglish,
      "show":this.data.eventId,
      "theater":this.form.value.theater.value,
      "date":JSON.stringify(this.form.value.showDate).replace('T', ' ') ,
      "entranceTime":`${this.form.value.timeHours.value}:${this.form.value.timeMins.value}`
      
      
    }).subscribe(res => {
      console.log(res)

      this._communicationService.showLoading(false) ; 
        this._dialog.closeAll();
      this._communicationService.notifyComponent('app-shows','refresh')

    } )
   
  }

  public closeCreateShow() {
    this._dialog.closeAll(); 
  }

  public changeMeridian() {
      this.meridian = !this.meridian
  }

  public ngOnInit() {

    this.currentLang = this._translate.currentLang;
    this.direction = this.currentLang === 'ar' ? 'rtl' : 'ltr';
    this._translate.getTranslation(this._translate.currentLang).subscribe(translation => {
      console.log("translation events" ,translation)
      this.translation = translation;
    });
   


    this._initializeForm(); 
    this.generateHours() ;
    this.generateMins();
  }

 
  private _initializeForm(): void {
    this.form = this._formBuilder.group({
      showDate: ['', [Validators.required]],
      timeHours: ['', [Validators.required]],
      timeMins: ['',  [Validators.required]],
      timeMeridian: ['AM'],
      nameArabic : ['' , Validators.required], 
      nameEnglish : ['', Validators.required], 
      theater : ['',Validators.required]
    });
   
  }
}
