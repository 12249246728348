import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {CommunicationService} from '../../services/communication/communication.service';
import {TranslateService} from '@ngx-translate/core';

import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { environment } from 'src/environments/environment';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { EventsService } from 'src/app/events/events.service';
import { Subscription } from 'rxjs';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { IOption } from 'src/app/modules/form/select/select.interface';
import { ShowsService } from 'src/app/shows/shows.service';
import { EmployeesServices } from 'src/app/employees/employees.service';
import { ZoneManageService } from 'src/app/zoneManagement/zoneManagement.service';
import { ReservationsShowsDialogComponent } from '../reservationsShows/reservationsShows.component';
import * as moment from 'moment';
import { sellingTicketsService } from 'src/app/sellingTickets/sellingTickets.service';
import { ReservationServices } from 'src/app/reservations/reservations.service';

@Component({
  selector: 'app-exchange-dialog',
  templateUrl: './exchange-dialog.component.html',
  styleUrls: ['./exchange-dialog.component.scss'],
})
export class ExchangeDialogComponent {

  public currentLang;
  public direction;
  public form: FormGroup;
  public translation;
  // public emailRegex = variables.emailRegex;
  public emailPatternErrorMessage;
  public validCaptcha: boolean;
  // public countries = countries;
  public isResponsive: boolean;
  public title = 'Contact Us'
  public siteKey: string = environment.captchaSiteKey; 
  public isLoading : boolean = false; 
  public isVendors : boolean = true;

  public permissionList  = []; 
  public permissionsForm = {} ; 
  public showsData; 
  public selectedShowsDate; 
  public seatingsResponse; 
  public selectedShow ; 
  public seatData; 
  public subscriptionData ; 
  public subscription;
  public seatmap; 
  constructor(@Inject(MAT_DIALOG_DATA) public data, 
              private _formBuilder: FormBuilder,
              private _utilitiesService: UtilitiesService,
              private _showsService: ShowsService, 
              private _EmployeesServices : EmployeesServices,
              private _communicationService: CommunicationService,
              private _permissionService: PermissionService,
              private _ZoneManageService : ZoneManageService, 
              private _ReservationServices: ReservationServices,
              private _ShowsService : ShowsService,
              private _sellingTicketsService : sellingTicketsService, 
              private _translate: TranslateService, private _dialog: MatDialog) {


    this.isVendors = this._permissionService.checkIsUserVendor();
    console.log( "isVendors",this.isVendors); 
    console.log(this.data)

    this.subscription = this._communicationService.getData().subscribe(response => {
      this.subscriptionData = response;
      this._subscriptionCallback();
    });

 
  }



  public chooseSeating(event) {
    console.log("select event", event); 
    this.seatData = event.seatsforDisplay[0]; 
   
  }


  public exchangeTicket () {
    this._communicationService.showLoading(true)
    this._ReservationServices.exchange(this.selectedShow._id, {
      rows: [this.data.ticketId],
      knet: 1,
      masterCard: 1,
      cash:1,
      place: [
            {
            "zone": this.seatData.zone_id,
            "seats":this.seatData.seat_id
            }
          ]
    }).subscribe(res => {
      this._communicationService.showLoading(false); 
      this._dialog.closeAll();
       this._communicationService.notifyComponent('app-reservations','refresh')
    })
  }

  public close() {
    this._dialog.closeAll(); 
  }



  public openShows() {
    this._dialog.open(ReservationsShowsDialogComponent, {
   data : {
    showsData : this.showsData, 
    exchange : true
   }
    })
  }


  public getShows(terms =''): void {
    
    this._communicationService.showLoading(true);
    this._ShowsService.getShowsList(this.data.eventId,this.currentLang).subscribe(response => {
      this.showsData = response['map'](show => {

        let formattedTime  = moment.tz(show.time, show.timezone).format('hh:mm ');
        let formattedDate  = moment.tz( show.date,  show.timezone).locale(this.currentLang == 'ar'? 'ar_SA':'en').format('dddd MMMM DD');

        show.formattedTime = formattedTime; 
        show.formattedDate = formattedDate; 
  
        return show; 
      }); 

      this.selectedShowsDate = this.formatDate(this.showsData[0].date )
      this.selectedShow =   this.showsData[0]; ;
      this.getMap(); 
    } , () => {
      this._communicationService.showLoading(false);
      this.showsData =[] ; 
    }, () => {
      this._communicationService.showLoading(false);
      // this.eventsData = response.data
    })
    
  }

  public getMap(): void {
    this._communicationService.showLoading(true);
    this._sellingTicketsService.getMap(this.selectedShow._id).subscribe(response => {

      console.log("test map", response); 
    
      this.seatingsResponse = response['seats']; 
      this._communicationService.showLoading(false); 
      this._communicationService.notifyComponent('app-seat-chart','refresh',{seatConfig : this.seatingsResponse});
      
    },
      err => {
        // this._communicationService.showLoading(false);
        // this._handleErrors(err);
      },
      () => {
        // for (const row of this.seatingsResponse) {
        //   row.seat_price = this.zonesResponse.zones[this.selectedZoneIndex].price;
        // }
        // // this._initializeForm();
        // window.scrollTo(0, 0);
        // this.showComponentStep = 3;
        // this._communicationService.showLoading(false);
      });
  }


  public formatDate(date,format = '') {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' , hour : 'numeric', minute:'numeric'};
    const dateObj = new Date(date); 

    return dateObj.toLocaleDateString(this.currentLang ,options)
  }

  public ngOnInit() {

    
    this.currentLang = this._translate.currentLang;
    this.direction = this.currentLang === 'ar' ? 'rtl' : 'ltr';
    this._translate.getTranslation(this._translate.currentLang).subscribe(translation => {
      console.log("translation events" ,translation)
      this.translation = translation;
    });
    console.log(this.data)
    this.getShows()

  }
  public getSelectedShow (showId : string) {
    
    this.selectedShow =   this.showsData.find(show => show._id === showId);
    if (this.selectedShow)
    {
      this.selectedShowsDate = this.formatDate(this.selectedShow.date) ;
       this.getMap()
    }
  }

  
  private _subscriptionCallback(): void {
    if (this.subscriptionData && typeof this.subscriptionData) {
      if (this.subscriptionData.notifyComponent === 'app-exchange-dialog') {
        console.log("this.subscriptionData",this.subscriptionData)
        switch (this.subscriptionData.action) {
          
            case 'refresh' : 
            this.getSelectedShow(this.subscriptionData.data.showId)
            break; 
         
          default:
            break;
        }
      }
    }
    
  }

 

}

