import {Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {environment} from '../../environments/environment';
import {variables} from '../app.variables';
import {ApiService} from '../services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ShowsService {

  constructor(private _apiService: ApiService) {
  }

  public getShows(showId : string,lang:string) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let url = environment.url + variables.shows.getShows;
  
      this._apiService.sendApi('get', url,
        {show : showId}, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

  public getShowsList(showId : string,lang:string,showdDuplicatedDates:boolean=false) {
    return new Observable(userInfoObservable => {
      console.log("test apis")
      let apiResponse;
      let url = environment.url + variables.shows.getShowsList;
  
      this._apiService.sendApi('get', url,
        {show : showId,showdDuplicatedDates}, false, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

  public getTheaterList() {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let url = environment.url + variables.shows.getTheater;
  
      this._apiService.sendApi('get', url,
        {}, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

  public createShows(body) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let url = environment.url + variables.shows.getShows;
  
      this._apiService.sendApi('post', url,
      body, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

  public editShow(body, showId:string) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let url = environment.url + variables.shows.editShow + `?_id=${showId}`;
  
      this._apiService.sendApi('put', url,
      body, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }


  public activateShow( users) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let url = environment.url + variables.shows.activateShow ;
  
      this._apiService.sendApi('put', url,
      {users}, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }
  public deactivateShow(users) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let url = environment.url + variables.shows.deactivateShow ;
  
      this._apiService.sendApi('put', url,
      {users}, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

  public deleteShow( showId:string) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let url = environment.url + variables.shows.deleteShow + `?_id=${showId}`;
  
      this._apiService.sendApi('delete', url,
      {}, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

 
}
