import {Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {environment} from '../../environments/environment';
import {variables} from '../app.variables';
import {ApiService} from '../services/api/api.service';
import { StorageService } from '../services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentsServices {

  constructor(private _apiService: ApiService) {
  }

  public getPayments(eventId:string, playId : string) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let userId = StorageService.getItem("_id"); 
      let endPoint =  variables.payments.getPaymentsInfo.replace(':showID', eventId); 
      let url = environment.url + endPoint;
  
      this._apiService.sendApi('get', url,
      {playId}, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

  public getPaymentsReport(eventId:string) {  
    return new Observable(userInfoObservable => {
      let apiResponse;
      let userId = StorageService.getItem("_id"); 
      let endPoint =  variables.payments.getPaymentsReport.replace(':showID', eventId); 
      let url = environment.url + endPoint;
  
      this._apiService.sendApi('get', url,
      {}, true,false,null ,false,null,true,{observe: 'response', responseType: 'blob'})
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

  public getSalesReport(eventId:string) {  
    return new Observable(userInfoObservable => {
      let apiResponse;
      let userId = StorageService.getItem("_id"); 
      let endPoint =  variables.payments.getDailyPayments.replace(':showID', eventId); 
      let url = environment.url + endPoint;
  
      this._apiService.sendApi('get', url,
      {}, true,false,null ,false,null,true,{observe: 'response', responseType: 'blob'})
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }




 
}
