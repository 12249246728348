import {Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {environment} from '../../environments/environment';
import {variables} from '../app.variables';
import {ApiService} from '../services/api/api.service';
import { StorageService } from '../services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeesServices {

  constructor(private _apiService: ApiService) {
  }

  public getEmployee(searchTerm : string = '',eventId :string) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let vendorID = StorageService.getItem(variables.storageVariables.vendor) ?StorageService.getItem(variables.storageVariables.vendor)._id : null; 
      let endPoint =  variables.employees.getEmployees.replace(':vendorID',vendorID).replace(':showID', eventId); 
      let url = environment.url + endPoint;
  
      this._apiService.sendApi('get', url,
      searchTerm ?   {email : searchTerm , name : searchTerm} : {}, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

  public getPermissions() {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let vendorID = StorageService.getItem(variables.storageVariables.vendor) ?StorageService.getItem(variables.storageVariables.vendor)._id : null; 
      let endPoint =  variables.employees.getPermissions.replace(':vendorID',vendorID)
      let url = environment.url + endPoint;
  
      this._apiService.sendApi('get', url,
        {}, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }


  public createEmployee(body,eventId) {

    return new Observable(userInfoObservable => {
      let apiResponse;
      let vendorID = StorageService.getItem(variables.storageVariables.vendor) ?StorageService.getItem(variables.storageVariables.vendor)._id : null; 
      let endPoint =  variables.employees.createEmployee.replace(':vendorID',vendorID).replace(':showID', eventId); 
      let url = environment.url + endPoint;
      console.log("body",body)
      this._apiService.sendApi('post', url,
      body, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
            return apiResponse
          }
        );
    });

  }

  public editEmployee(body,eventId:string,employeeId:string) {

    return new Observable(userInfoObservable => {
      let apiResponse;
      let vendorID = StorageService.getItem(variables.storageVariables.vendor) ?StorageService.getItem(variables.storageVariables.vendor)._id : null; 
      let endPoint =  variables.employees.editEmployee.replace(':vendorID',vendorID).replace(':showID', eventId).replace(':userID',employeeId); ; 
      let url = environment.url + endPoint;
      console.log("body",body)
      this._apiService.sendApi('put', url,
      body, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

  public deleteEmployee( eventId:string,employeeId:string) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let vendorID = StorageService.getItem(variables.storageVariables.vendor) ?StorageService.getItem(variables.storageVariables.vendor)._id : null; 
      let endPoint =  variables.employees.deleteEmployee.replace(':vendorID',vendorID).replace(':showID', eventId).replace(':userID',employeeId); 
      let url = environment.url + endPoint;
      this._apiService.sendApi('delete', url,
      {}, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }
 
}
