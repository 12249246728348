import {Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {environment} from '../../environments/environment';
import {variables} from '../app.variables';
import {ApiService} from '../services/api/api.service';
import { StorageService } from '../services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class StatisticsServices {

  constructor(private _apiService: ApiService) {
  }

  public getReservationsStat(eventId:string, filters) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let userId = StorageService.getItem("_id"); 
      let endPoint =  variables.statistics.getReservationsStat.replace(':showID', eventId); 
      let url = environment.url + endPoint;
  
      this._apiService.sendApi('get', url,
      filters, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

  public getTicketsStat(eventId:string,  filters) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let userId = StorageService.getItem("_id"); 
      let endPoint =  variables.statistics.getTicketsStatistics.replace(':showID', eventId); 
      let url = environment.url + endPoint;
  
      this._apiService.sendApi('get', url,
      filters, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }


  public getAttendancesStat(eventId:string, filters) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let userId = StorageService.getItem("_id"); 
      let endPoint =  variables.statistics.getAttendance.replace(':showID', eventId); 
      let url = environment.url + endPoint;
  
      this._apiService.sendApi('get', url,
      filters, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }






 
}
