import {Injectable} from '@angular/core';
import {PermissionService} from './permission.service';
import {UtilitiesService} from '../utilities.service';
import {ActivatedRoute, RouterStateSnapshot} from '@angular/router';
import { variables } from 'src/app/app.variables';

@Injectable({
  providedIn: 'root'
})
export class  AuthGuardService {

  public vendorsPaths = [ ...Object.values(variables.vendorsRoutes) ];
  public vendorAccess = variables.vendorsRoutesAccess;
  public customersPaths = [...Object.values(variables.routes) ] 
  constructor(
    private _PermissionService: PermissionService,
    private _utilitiesService: UtilitiesService, 
    private _router : ActivatedRoute, 
  ) {
  //   this._router.params.subscribe( params =>{ 
  //     console.log(params); 
  //     // this.eventId = params['bank'];
  //     this.eventId = params['eventId']; 
  //     console.log(this.eventId, "test me")
  //   }
  // )
  }
  private checkAuthPass (state: RouterStateSnapshot) :boolean {

    if (state.url && state.url[0] &&  state.url[0]['path'] && state.url[1] && state.url[1]['path'])
      {
     
        if (state.url[0]['path'] === 'a') { // tslint:disable-line
          if ( (state.url[1]['path'] === 'sign-in' ||state.url[1]['path'] === 'sign-up')) { // tslint:disable-line
              return true;
          }
      } 
    
      }

      return false ; 
    
  }
  private checkRootPass (state : RouterStateSnapshot) : boolean {
    if(state && state.url && !state.url.length)
      {
        return true; 
      }
      return  false ; 
  }
  public canActivate(state: RouterStateSnapshot): boolean {


    const isAuthPass = this.checkAuthPass(state); 
    const isRootPass = this.checkRootPass(state); 
    console.log(state.url[0])
    if (this._PermissionService.checkUserLoggedIn())
    {
    
       if (this._PermissionService.checkIsUserVendor())
       {
          if (isAuthPass || isRootPass)
          {
            this._utilitiesService.routeToEvents(); 
            return false; 
          }
     
          const accessNeeded = this.vendorAccess[state.url[0]['path']]; 
          const accessHave = this._PermissionService.getPermissionAccess(state['params'].eventId); 
          const isMasterAccess = this._PermissionService.isMasterAccess();
         
          if (this.vendorsPaths.find(route =>  route == state.url[0]['path']) && (!accessNeeded || accessHave.indexOf(accessNeeded) !== -1 || isMasterAccess))
            return true ; 
        this._utilitiesService.routeToEvents(); 
        return false; 
       }
       else 
       {
          if (isAuthPass)
          {
            this._utilitiesService.routeToDashboard(); 
            return false; 
          }
          if (isRootPass)
          return true ; 
          if (this.vendorsPaths.find(route => route == state.url[0]['path']))
          {
            this._utilitiesService.routeToDashboard(); 
            return false ; 
          }
     
          // if ()
          return true; 
       }
  
    }
    if (isRootPass)
    return true ; 
    if (this.vendorsPaths.find(route => route == state.url[0]['path']))
    {
      this._utilitiesService.routeToDashboard(); 
      return false ; 
    }
  
    if (state.url[0]['path'] === "showDetails" || state.url[0]['path'] === "buyTicket" || state.url[0]['path'] === 'mytickets')
      this._utilitiesService.routeToLogin(); 
      
   
    return true;
  }
}
