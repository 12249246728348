/* tslint:disable */

import { Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';
import { CommunicationService } from 'src/app/services/communication/communication.service';

@Component({
  selector: 'app-seat-chart',
  templateUrl: './seat-chart.component.html',
  styleUrls: ['./seat-chart.component.scss']
})
export class SeatChartComponent implements OnInit {

  @Input() public zone;
  @Input() public seatConfig;
  @Input() public direction = 'ltr';
  @Input() public currentLang;
  @Input() public seatmap = [];
  @Input() public isVendors:any = false ; 
  @Input() public showZoneColor = false ; 
  public disableMiddleSeatsPick = true;
  @Input() public seatWidth ;
  @Input() public highLightedZone  ; 
  @Input() public zoneManage; 
  @Input() public selectedSeats = [] ; 
  @Input() public exchange;
  @Input() public selectedSeatExchange; 
  public subscriptionData; 
  public subscription; 

  public seatChartConfig = {
    showRowsLabel: true,
    showRowWisePricing: false,
    newSeatNoForRow: true
  };
  public cart = {
    selectedSeats: [],
    seatstoStore: [],
    seatsforDisplay: [],
    totalamount: 0,
    cartId: '',
    eventId: 0
  };
  public seatBookedBackground: "#febf24";
  @Output() public selectSeatEvent = new EventEmitter();

  constructor(
    private _communicationService : CommunicationService
  ) {

    
    this.subscription = this._communicationService.getData().subscribe(response => {
      this.subscriptionData = response;
      this._subscriptionCallback();
    });

  }

  public ngOnInit() {

    this.seatmap = [];
    console.log("this.seatConfig",this.seatConfig)
    this.processSeatChart(this.seatConfig);
    console.log("selectedSeatExchange",this.selectedSeatExchange)
  }

  public ngAfterViewInit(){
    this.calculateSeatWidth()
  }
  public processSeatChart(map_data = []) {
    console.log("map_data",map_data)
    // this.seatmap = [];

    if (map_data.length > 0) {
      let seatNoCounter = 1;
      for (let __counter = 0; __counter < map_data.length; __counter++) {
        if (this.seatChartConfig.newSeatNoForRow) {
          seatNoCounter = 1;
        }
        let row_label = '';
        const item_map = map_data[__counter].seats;
        row_label = 'Row ' + map_data[__counter].name + ' : ' + map_data[__counter].seat_price;

        const mapObj = {
          seatRowLabel: map_data[__counter].name,
          direction: map_data[__counter].direction,
          seats: [],
          seatPricingInformation: row_label
        };
        let totalItemCounter = 1;
        item_map.forEach(map_element => {
          row_label = '';
          const seatObj = {
            key: map_data[__counter].name + '_' + map_element.seat_label,
            // key: map_data[__counter].name + '_' + totalItemCounter,
            price: this.isVendors ? map_element['zone_price'] : map_data[__counter]['seat_price'],
            status: map_element.status,
            seat_id: map_element._id,
            zone_color: map_element.zone_color,
            isDimmed: map_element.isDimmed, 
            zone_id : map_element['zone_id'], 
          };
          if (seatObj.status !== 'empty') {
            // seatObj['seatLabel'] = map_data[__counter].name + ' ' + seatNoCounter;
            seatObj['seatLabel'] = map_data[__counter].name + ' ' + map_element.seat_label;
            // if (seatNoCounter < 10) {
            if (map_element.seat_label < 10) {
              seatObj['seatNo'] = '0' + seatNoCounter;
              // seatObj['seatNo'] = map_element.seat_label;
            } else {
              // seatObj['seatNo'] = '' + seatNoCfounter;
              seatObj['seatNo'] = '' + map_element.seat_label;
            }
            seatNoCounter++;
            totalItemCounter++;
          } else {
            seatObj['seatLabel'] = '';
          }
          
          mapObj['seats'].push(seatObj);
        });

        console.log("mapObj",mapObj)

        this.seatmap.push(mapObj);


      }


    }

    console.log("test temp", this.seatmap)
  }
  public calculateSeatWidth(){
    const seatsNumberInRow=(this.seatmap[0]['seats'].length)
    console.log("seatsNumberInRow:",seatsNumberInRow)

    const totalSeatsIncludingMargins=(seatsNumberInRow*2.3) //margin=50% of seats size
    // document.getElementById("seatRow").style.width=''
    // alert("dddddd:",document.getElementById("seatrow222"))
    // console.log("document------------------- : ",document.getElementById("seatrow222"))
    const seatMapWidth=document.getElementById("seatRowww").offsetWidth
    console.log("Map Width:",seatMapWidth)
    const Width=seatMapWidth/totalSeatsIncludingMargins
    this.seatWidth=Width+'px'
    console.log("seat Width:",this.seatWidth)
    document.getElementById("seat-item").style.width=this.seatWidth
    document.getElementById("seat-item").style.height=this.seatWidth


  }

  public checkIfActionAllowed(seatObject, row) {
    let allowSelect = false;
    for (let i = 0; i < row.seats.length; i++) {
      if (row.seats[i].seatNo === seatObject.seatNo) {
        if (i !== 0 && i !== row.seats.length - 1) {
          let previousRow = row.seats[i - 1].status;
          let nextRow = row.seats[i + 1].status;
          let isPreviousSeatUnavailabe = previousRow === 'reserved' || previousRow === 'booked';
          let isNextSeatUnavailabe = nextRow === 'reserved' || nextRow === 'booked';
          if (seatObject.status === 'available') {
            console.log("here from condition")
            if ( (this.isVendors && this.isVendors!=='false' )|| isPreviousSeatUnavailabe || isNextSeatUnavailabe || previousRow === 'empty' || nextRow === 'empty' || previousRow === 'blocked' || nextRow === 'blocked') {
              allowSelect = true;
              break;
            }
          }
          if (seatObject.status === 'booked') {
            allowSelect = false;

            // if the previous seat is an empty seat then we check on the next seats to allow the unselection
            if (previousRow === 'empty' || previousRow === 'blocked') {
              allowSelect = this.checkNextSeats(i, row);
            }
            // if the next seat is an empty seat then we check on the previous seats to allow the unselection
            else if (nextRow === 'empty' || previousRow === 'blocked') {
              allowSelect = this.checkPreviousSeats(i, row);
            }
            // if the previous seat is an reserved seat then we check on the next seats to allow the unselection
            else if (previousRow === 'reserved') {
              allowSelect = this.checkNextSeats(i, row);
            }
            // if the next seat is an reserved seat then we check on the previous seats to allow the unselection
            else if (nextRow === 'reserved') {
              allowSelect = this.checkPreviousSeats(i, row);
            }
            else {
              // if the next seat is booked or available or the previous seat is booked or available then we check on these conditions
              allowSelect = true;
              if ((previousRow === 'booked' && nextRow === 'booked')
                || (previousRow === 'empty' && nextRow === 'booked')
                || (previousRow === 'blocked' && nextRow === 'booked')
                || (previousRow === 'booked' && nextRow === 'empty')
                || (previousRow === 'booked' && nextRow === 'blocked')
                || (previousRow === 'reserved' && nextRow === 'booked')
                || (previousRow === 'booked' && nextRow === 'reserved')) {
                allowSelect = false;
              }
            }
          }
        } else {
          if (seatObject.status === 'available') {
            allowSelect = true;
            break;
          } else {
            if (i === 0) {
              allowSelect = this.checkNextSeats(i, row);
            } else if (i === row.seats.length - 1) {
              allowSelect = this.checkPreviousSeats(i, row);
            }
          }
        }
      }
    }
    return allowSelect;
  }

  // function to check on the seats after the selected seat (if the direct next seat is available then he can unselect the seat,
  // if all next seats are booked and he arrives at a reserved seat or empty seat or at the end of the theater then he can unselect the seat
  public checkNextSeats(selectedSeatIndex, row) {
    let allowSelect = false;
    for (let x = selectedSeatIndex + 1; x < row.seats.length; x++) {
      if (row.seats[x].status === 'available') {
        if (x === selectedSeatIndex + 1) {
          allowSelect = true;
        }
        break;
      }
      if (row.seats[x].status === 'reserved' || row.seats[x].status === 'empty' || row.seats[x].status === 'blocked' || x === row.seats.length - 1) {
        allowSelect = true;
        break;
      }
    }
    return allowSelect;
  }

  // function to check on the seats before the selected seat (if the direct previous seat is available then he can unselect the seat,
  // if all previous seats are booked and he arrives at a reserved seat or empty seat or at the beginning of the theater then he can unselect the seat
  public checkPreviousSeats(selectedSeatIndex, row) {
    let allowSelect = false;
    for (let x = selectedSeatIndex - 1; x > -1; x--) {
      if (row.seats[x].status === 'available') {
        if (x === selectedSeatIndex - 1) {
          allowSelect = true;
        }
        break;
      }
      if (row.seats[x].status === 'reserved' || row.seats[x].status === 'empty' || row.seats[x].status === 'blocked' || x === 0) {
        allowSelect = true;
        break;
      }
    }
    return allowSelect;
  }

  public selectSeat(seatObject, seatrow,event) {
    console.log("seatrow",seatrow)
    console.log("seatObject",seatObject)
    if (this.zoneManage)
    {
      this.selectSeatEvent.emit({seatObject , seatrow});
    }
    if (this.cart.selectedSeats.length && this.exchange && seatObject.status !=='booked')
      return; 
   
    if (!seatObject.isDimmed) {
      let allowSelect = false;
      if (this.disableMiddleSeatsPick) {
        allowSelect = this.checkIfActionAllowed(seatObject, seatrow);
      }
      console.log("from test here", allowSelect , this.disableMiddleSeatsPick)
      if (!this.disableMiddleSeatsPick || (this.disableMiddleSeatsPick && allowSelect)) {
       
        if (seatObject.status === 'available') {
          seatObject.status = 'booked';
          seatObject.original_zone_color=seatObject.zone_color
          seatObject.zone_color=this.seatBookedBackground
          this.cart.selectedSeats.push(seatObject.seatLabel);
          this.cart.seatstoStore.push(seatObject.seat_id);
          this.cart.seatsforDisplay.push({
            row: seatObject.key.split('_')[0],
            seat: seatObject.key.split('_')[1],
            price: seatObject.price,
            color: seatObject.zone_color, 
            zone_id : seatObject.zone_id, 
            seat_id : seatObject.seat_id
          });
          this.cart.totalamount += seatObject.price;
        } else if (seatObject.status = 'booked') {
          seatObject.status = 'available';
          console.log("seatObject:",seatObject)
          seatObject.zone_color=seatObject.original_zone_color
          let seatIndex = this.cart.selectedSeats.indexOf(seatObject.seatLabel);
          if (seatIndex > -1) {
            this.cart.selectedSeats.splice(seatIndex, 1);
            this.cart.seatstoStore.splice(seatIndex, 1);
            this.cart.seatsforDisplay.splice(seatIndex, 1);
            this.cart.totalamount -= seatObject.price;
          }

        }
        console.log("this.cart",this.cart)
        this.selectSeatEvent.emit(this.cart);
      }
    }

  }

  private _subscriptionCallback(): void {
    if (this.subscriptionData && typeof this.subscriptionData) {
      if (this.subscriptionData.notifyComponent === 'app-seat-chart') {
        switch (this.subscriptionData.action) {
            case 'refresh':
              this.seatmap = [] ; 
              this.cart = {
                selectedSeats: [],
                seatstoStore: [],
                seatsforDisplay: [],
                totalamount: 0,
                cartId: '',
                eventId: 0
              }
            console.log("this.subscriptionData",this.subscriptionData)
            this.processSeatChart(this.subscriptionData.data.seatConfig)
            break;
         
         
          default:
            break;
        }
      }
    }
    
  }
}
