import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Field} from "../form.interface";
import { IOption } from '../select/select.interface';

@Component({
    selector: 'app-multiselect',
    templateUrl: './multiselect.component.html',
    styleUrls: ['./multiselect.component.scss']
})
export class MultiselectComponent implements OnInit {

    @Input() element: Field;
    @Input() value: any;
    @Input() floatLabel: boolean;
    @Input() public currentLang: string; 
    @Input() public title: string;
    @Input() public singleSelection: boolean;
    @Input() public text: string;
    @Input() public selectAllText: string;
    @Input() public unSelectAllText: string;
    @Input() public enableSearchFilter = true;
    @Input() public searchPlaceholderText: string;
    @Input() public noDataAvailablePlaceholderText: string;
    @Input() public limitSelection: number;
    @Input() public selectedItems = [];
    @Input() public group: FormGroup;
    @Input() public name: string;
    @Input() public options: IOption[];
    @Output() public eventEmitter = new EventEmitter();
    public dropdownSettings = {};

    public ngOnInit() {
        for (let option of this.options) {
            option['itemName'] = option.text;
            option['id'] = option.value;
        }
        const currentSelectData = this.group.get(this.name); 
        console.log(this.group.get(this.name).hasError('required'))
    
        if (currentSelectData.value && currentSelectData.value.length > 0) {
            for(let selectedOptions of currentSelectData.value) {
                for (let option of this.options) {
                    
                    if (selectedOptions.value == option['id']) {
                        this.selectedItems.push(option);
                    }
                }
            }
    }

        this.dropdownSettings = {
            singleSelection: this.singleSelection,
            text: this.currentLang == 'ar' ? "يختار" : 'Select',
            selectAllText: this.currentLang == 'ar' ? "اختر الكل" : 'Select All',
            unSelectAllText: this.currentLang == 'ar' ? "إلغاء تحديد الكل" :  'Unselect All',
            enableSearchFilter: this.enableSearchFilter,
            searchPlaceholderText: this.currentLang == 'ar' ? "بحث" : 'Search',
            noDataLabel:  this.currentLang == 'ar' ? 'لا تتوافر بيانات' : 'No Data Available',
           
            // disabled: this.element.readonly,
            limitSelection: this.limitSelection,
            closeDropDownOnSelection: false,
            classes: 'multiselect-custom',
            searchBy: ['itemName'],
            maxHeight:150,
            position:'bottom'
        };
    }

    public onItemSelect() {
        this.changeValue();
    }

    public OnItemDeSelect() {
        this.changeValue();
    }

    public onSelectAll() {
        this.changeValue();
    }

    public onDeSelectAll() {
        this.changeValue();
    }

    public saveNewValue() {
        let value = [];
        for (let item of this.selectedItems) {
            value.push(item.value);
        }
        return value;
    }

    public changeValue() {
        this.eventEmitter.emit({
            'name': this.name,
            'value': this.saveNewValue(),
            'type':'multi-select'
        });
    }

}
