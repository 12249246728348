import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {CommunicationService} from '../../services/communication/communication.service';
import {TranslateService} from '@ngx-translate/core';

import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { environment } from 'src/environments/environment';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { EventsService } from 'src/app/events/events.service';
import { Subscription } from 'rxjs';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { IOption } from 'src/app/modules/form/select/select.interface';
import { ShowsService } from 'src/app/shows/shows.service';
import { EmployeesServices } from 'src/app/employees/employees.service';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-dialog-create-shows',
  templateUrl: './createEmployeesDialog.component.html',
  styleUrls: ['./createEmployeesDialog.component.scss'],
})
export class CreateEmployeesDialogComponent {

  public currentLang;
  public direction;
  public form: FormGroup;
  public translation;
  // public emailRegex = variables.emailRegex;
  public emailPatternErrorMessage;
  public validCaptcha: boolean;
  // public countries = countries;
  public isResponsive: boolean;
  public title = 'Contact Us'
  public siteKey: string = environment.captchaSiteKey; 
  public isLoading : boolean = false; 
  public isVendors : boolean = true;

  public permissionList  = []; 
  public permissionsForm = {} 

  constructor(@Inject(MAT_DIALOG_DATA) public data, 
              private _formBuilder: FormBuilder,
              private _utilitiesService: UtilitiesService,
              private _showsService: ShowsService, 
              private _EmployeesServices : EmployeesServices,
              private _communicationService: CommunicationService,
              private _permissionService: PermissionService,
              private _translate: TranslateService, private _dialog: MatDialog) {


    this.isVendors = this._permissionService.checkIsUserVendor();
    console.log( "isVendors",this.isVendors)
 
  }






  public submitForm() {


    console.log(this.form)
    console.log(this.data.employeesPermissions)
    const permissionsEnums = Object.keys(this.form.value).filter(item => typeof this.form.value[item] ==='boolean' && this.form.value[item] === true).map(item => item); 
    const assignedPermissionsIds = this.data.employeesPermissions.filter(item => {
      const found = permissionsEnums.find(itemEnums => itemEnums === item.value); 
      return found; 
    })
  if (this.data.employee)
  {
    this._communicationService.showLoading(true);
    this._EmployeesServices.editEmployee({
      name: this.form.value.employeeName,
      permissions: assignedPermissionsIds.map(item => item._id)
    }, this.data.eventId, this.data.employee._id).subscribe(res => {
      this._communicationService.showLoading(false);
      this._dialog.closeAll(); 
      this._communicationService.notifyComponent('app-employees','refresh')
    }, err => {
      this._handleErrors(err);
        this._communicationService.showLoading(false);
    })
  }
  else 
  {
    this._communicationService.showLoading(true);
    this._EmployeesServices.createEmployee({
      email : this.form.value.email, 
      name: this.form.value.employeeName,
      phoneNumber : this.form.value.clientPhoneLine,
      permissions: assignedPermissionsIds.map(item => item._id)
    }, this.data.eventId).subscribe(res => {
      this._communicationService.showLoading(false);
      this._dialog.closeAll(); 
      this.employeeCreationDialog(res['body'])
      this._communicationService.notifyComponent('app-employees','refresh')
    }, err => {
      this._handleErrors(err);
        this._communicationService.showLoading(false);
    })
  }
  
   
  }

  public closeEmployee() {
    this._dialog.closeAll(); 
  }

  public formattingPermissions () {

    console.log("this.data", this.data)
 
    this.permissionList =  this.data.employeesPermissions.map(mission => {
      let found = false ;
      if (this.data.employee)
      {
        found = this.data.employee.access.permissions.find(item => item._id === mission._id)

      }
      return ({
        check : !!found , 
        name : mission.value, 
        label : this.currentLang === 'ar' ? mission.name_ar : mission.name_en, 
        hint : this.currentLang === 'ar' ? mission.desc_ar : mission.desc_en,  
      })
    })

    console.log("permissionList",this.permissionList)
     
    

    for(let mission of this.data.employeesPermissions) {
      let found = false ;
      if (this.data.employee)
      {
        found = this.data.employee.access.permissions.find(item => item._id === mission._id)

      }
        this.permissionsForm[mission.value] = [!!found]
    }

    console.log(this.permissionsForm)
  }


  public ngOnInit() {

    console.log("employeesPermissions",this.data.employeesPermissions)
    this.currentLang = this._translate.currentLang;
    this.direction = this.currentLang === 'ar' ? 'rtl' : 'ltr';
    this._translate.getTranslation(this._translate.currentLang).subscribe(translation => {
      console.log("translation events" ,translation)
      this.translation = translation;
    });
   

    this.formattingPermissions();
    this._initializeForm(); 

  }

  public showAlert(title: string, content: string): void {
    const dialogId = new Date();
    this._communicationService.setLatestDialogId(dialogId);
    this._dialog.open(DialogComponent, {
      data: {
        title,
        content,
        isConfirmationPopUp: false
      }
    });
  }

  private _handleErrors(error) {
    switch (error.status) {
      default:
        this.showAlert(this.translation.GENERAL_ERRORS.INTERNAL_ERROR_TITLE, this.translation.GENERAL_ERRORS.INTERNAL_ERROR_TEXT);
        break;
    }
  }

 
  private _initializeForm(): void {
    console.log(this.data)
    this.form = this._formBuilder.group({
      employeeName: [this.data.employee ? this.data.employee.name : '' , [Validators.required]],
      email : [this.data.employee ? this.data.employee.appUserEmail : ''  , [Validators.required, Validators.email]],
      clientPhoneCountryCode : ['KW', Validators.required], 
      clientPhoneLine : ['',Validators.required], 
      clientPhone : [''],
 
      ...this.permissionsForm,
      
    
    });
   
  }
  public employeeCreationDialog(res): void {
    const dialogId = new Date();
    this._communicationService.setLatestDialogId(dialogId);
    this._dialog.open(DialogComponent, {
      data: {
        title: `${this.translation.EMPLOYEES.employeeCreated} ${res.password}`,
        notifyComponent: 'app-top-bar'
      }
    });
  }

}
