import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {CommunicationService} from '../../services/communication/communication.service';
import {TranslateService} from '@ngx-translate/core';

import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { environment } from 'src/environments/environment';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { EventsService } from 'src/app/events/events.service';
import { Subscription } from 'rxjs';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { EventDetailsService } from 'src/app/eventDetails/eventDetails.service';

@Component({
  selector: 'app-dialog-edit',
  templateUrl: './editEventsDialog.component.html',
  styleUrls: ['./editEventsDialog.component.scss']
})
export class EditEventsDialogComponent {

  public currentLang;
  public direction;
  public form: FormGroup;
  public translation;
  // public emailRegex = variables.emailRegex;
  public emailPatternErrorMessage;
  public validCaptcha: boolean;
  // public countries = countries;
  public isResponsive: boolean;
  public title = 'Contact Us'
  public siteKey: string = environment.captchaSiteKey;
  public crewTypes :string[] = ['actors', 'authors', 'directors'] ; 
  public crews  = {} ; 
  public isLoading : boolean = false; 
  public isVendors;
  public uploadedImg : {Image :null , Cover : null} = {Image : null , Cover : null}; 

  constructor(@Inject(MAT_DIALOG_DATA) public data, 
              private _formBuilder: FormBuilder,
              private _utilitiesService: UtilitiesService,
              private _eventsService: EventsService, 
              private _eventDetailsService: EventDetailsService, 
              private _communicationService: CommunicationService,
              private _permissionService: PermissionService,
              private _translate: TranslateService, private _dialog: MatDialog) {


    this.isVendors = this._permissionService.checkIsUserVendor();
    console.log( this.isVendors)
 
  }




  public confirmation(): void {
    this._communicationService.notifyComponent(this.data.notifyComponent, this.data.action, null);
  }

  public submitForm() {
    this._communicationService.showLoading(true) 
   this._eventDetailsService.editEvents({
    name_en : this.data.form.value.englishName  || this.data.eventData.name.en, 
    name_ar : this.data.form.value.arabicName  || this.data.eventData.name.ar, 
    duration :  this.data.form.value.duration || this.data.eventData.duration,
    description_ar : this.data.form.value.descArabic || this.data.eventData.description.ar,
    description_en : this.data.form.value.descEnglish || this.data.eventData.description.en,
    image : this.data.eventData.image, 
    cover : this.data.eventData.cover, 
    actors : this.data.form.value.actors || this.data.eventData.actors.map(actor => actor._id)    ,
    authors : this.data.form.value.authors  || this.data.eventData.authors.map(author => author._id)  ,
    directors : this.data.form.value.directors || this.data.eventData.directors.map(director => director._id)  ,
  }, this.data.eventData._id).subscribe(response =>{
    console.log(response)
    this._communicationService.showLoading(false) ; 
    this._communicationService.notifyComponent('app-event-details','close-dialog'); 
    this._communicationService.notifyComponent('app-event-details','refresh')
  } )
  }

  public setMultiSelect(val) {
    console.log(val)
    if (val.value.length)
    {
      this.data.form.get(val.name).setValue(val.value); 
      this.data.form.get(val.name).markAsTouched();
    }
   
    else 
    {
      this.data.form.get(val.name).setValue(''); 
      this.data.form.get(val.name).markAsTouched();
    }
   
  }

  public ngOnInit() {
    this.currentLang = this._translate.currentLang;
    this.direction = this.currentLang === 'ar' ? 'rtl' : 'ltr';
    this._translate.getTranslation(this._translate.currentLang).subscribe(translation => {
      console.log("translation events" ,translation)
      this.translation = translation;
    });
    this._communicationService.showLoading(true); 

    this.crewTypes.forEach(type => {

      this._eventsService.getCrews(type).subscribe(response => {
        console.log(response)
        this.crews[type] = response['map'](item => ({text : item.name , value : item._id})); 
     
        if (Object.keys(this.crews).length == 3)
         this.isLoading = true ; 
      } , () => {} , () => {
    
      })

    }); 
    this._communicationService.showLoading(false); 


    this._initializeForm(); 
  }


  private setImageData(imageData) {

    this.uploadedImg[imageData['fieldName']] = imageData; 
 

  }


  private _initializeForm(): void {
    this.form = this._formBuilder.group({
      englishName: ['', [Validators.required]],
      arabicName: ['', [Validators.required]],
      descEnglish: ['',  [Validators.required]],
      descArabic: ['',  [Validators.required]],
      duration: ['',  [Validators.required]],
      actors: ['', [Validators.required]], 
      authors: ['', [Validators.required]],
      directors: ['', [Validators.required]]
    });
   
  }
}
