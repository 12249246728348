import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { variables } from '../../app.variables';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  public checkUserLoggedIn() {
    return !!StorageService.getItem(variables.storageVariables.AccessToken);
  }

  public checkIsUserVendor() {

    if (StorageService.getItem(variables.storageVariables.profiles) || StorageService.getItem(variables.storageVariables.vendor))
    {
      const profiles  = StorageService.getItem(variables.storageVariables.profiles) ? StorageService.getItem(variables.storageVariables.profiles) : []; 
      const vendor  = StorageService.getItem(variables.storageVariables.vendor); 
      if (profiles.length)
        return profiles[0].forVendors ; 
      if (vendor)
        return vendor.type === 'client'
    }
    return false;
  }

  public getPermissionAccess (eventId:string) {
    if (StorageService.getItem(variables.storageVariables.access))
    {
      const permissionAccess = StorageService.getItem(variables.storageVariables.access); 
      if (permissionAccess[eventId])
      {
        return permissionAccess[eventId].map(access => access.value); 
      }
    }
    return []; 
  }

  public isMasterAccess() {
    if (StorageService.getItem(variables.storageVariables.masterAccess))
    {
      return StorageService.getItem(variables.storageVariables.masterAccess)
    }
  }

  public checkUserAllowedToRoute() {
    if (StorageService.getItem(variables.storageVariables.allowedToRoute) === false
      || StorageService.getItem(variables.storageVariables.allowedToRoute) === 'false') {
      return false;
    }
    return true;
  }
}
