import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {CommunicationService} from '../../services/communication/communication.service';
import {TranslateService} from '@ngx-translate/core';

import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { environment } from 'src/environments/environment';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { EventsService } from 'src/app/events/events.service';
import { Subscription } from 'rxjs';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { IOption } from 'src/app/modules/form/select/select.interface';
import { ShowsService } from 'src/app/shows/shows.service';
import { ActivatedRoute } from '@angular/router';
import * as HighCharts from "highcharts";
import { StatisticsServices } from 'src/app/statistics/statistics.service';
import * as moment from 'moment';
// @ts-ignore
require('highcharts/modules/exporting')(HighCharts);
// @ts-ignore
require('highcharts/modules/export-data')(HighCharts);
// @ts-ignore
require('highcharts/modules/annotations')(HighCharts);

@Component({
  selector: 'app-eventDashboardFooter',
  templateUrl: './eventDashboardFooter.component.html',
  styleUrls: ['./eventDashboardFooter.component.scss'],
})
export class EventDashboardFooterComponent {

  public currentLang;
  public direction;
  public form: FormGroup;
  public translation;
  // public emailRegex = variables.emailRegex;
  public emailPatternErrorMessage;
  public validCaptcha: boolean;
  // public countries = countries;
  public isResponsive: boolean;
  public siteKey: string = environment.captchaSiteKey;
  public crewTypes :string[] = ['actors', 'authors', 'directors'] ; 
  public crews  = {} ; 
  public isLoading : boolean = false; 
  public isVendors : boolean = true;
  public totalCount;
  public eventId; 
  public reservationsStatistics;
  public highcharts; 
  public config; 
  public subscriptionData; 
  public subscription; 
  constructor(
              private _formBuilder: FormBuilder,
              private _utilitiesService: UtilitiesService,
              private _showsService: ShowsService, 
              private _communicationService: CommunicationService,
              private _permissionService: PermissionService,
              private _router : ActivatedRoute, 
              private _UtilitiesService : UtilitiesService,
              private _StatisticsServices : StatisticsServices, 
              private _translate: TranslateService, private _dialog: MatDialog) {

                this.subscription = this._communicationService.getData().subscribe(response => {
                  this.subscriptionData = response;
                  this._subscriptionCallback();
                });


    this.isVendors = this._permissionService.checkIsUserVendor();
    console.log( "isVendors",this.isVendors)
    this._router.params.subscribe( params =>{ 
      console.log(params); 
  
      this.eventId = params['eventId']; 
    }
  
  )
 
  }


 








  public ngOnInit() {

    this.currentLang = this._translate.currentLang;
    this.direction = this.currentLang === 'ar' ? 'rtl' : 'ltr';
    this._translate.getTranslation(this._translate.currentLang).subscribe(translation => {
      console.log("translation events" ,translation)
      this.translation = translation;
    });
   

    this.getShows(); 
    this.getReservationsStatistics()
   
  }

  public redirectToEventDetails() {

    this._UtilitiesService.routeToEventDetails(this.eventId)

  }

  public redirectToShows() {


    this._UtilitiesService.routeToShows(this.eventId)

  }

  public prepareReservatioStatisticsCharts () {
    console.log(HighCharts); 
    const categories = this.reservationsStatistics.map(item => item._id); 
    const reservationsCount = this.reservationsStatistics.map(item => item.count); 
    const formattedCat = categories.map(item => moment(item).format('DD MMM'))

    this.highcharts = HighCharts; 
    this.config = { 
         chart: { 
             type: "column", 
             backgroundColor: 'rgb(31,34,39)',
             allowHTML:true, 
             NavigationButtonOptions : true 
         }, 
         title: { 
             text:this.translation.RESERVATIONS.dailyStat, 
             style : {
              color : "white"
             }
         }, 
         exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                'viewFullscreen', 'separator', 'downloadPNG',
                'downloadSVG', 'downloadPDF', 'separator', 'downloadXLS'
              ]
            },
          },
          enabled: true,
        },
    navigation: {
      buttonOptions: {
        align: 'right',
        verticalAlign: 'top',
        y: 0
      }
    },
     
         xAxis: { 
             categories : formattedCat
         }, 
         yAxis: { 
             title: { 
                 text: "Values", 
             }, 
         }, 
        //  tooltip: { 
        //      valueSuffix: " K", 
        //  }, 
         plotOptions: {
          series: {
              stacking: 'normal',
              dataLabels: {
                  enabled: true
              }
          },
      },
         series: [{
          name: this.translation.RESERVATIONS.reservations,
          data: reservationsCount
      }], 
   
     };

  }

  public getReservationsStatistics () {
    this._communicationService.showLoading(true)
    this._StatisticsServices.getReservationsStat(this.eventId,{}).subscribe(res => {
      console.log("getReservationsStatistics",res)
      this.reservationsStatistics = res['result']; 
      this.prepareReservatioStatisticsCharts() ;
      this._communicationService.showLoading(false); 

    })
  }

 

  public getShows(terms =''): void {
    this._communicationService.showLoading(true);
    this._showsService.getShows(this.eventId,this.currentLang).subscribe(response => {
      this.totalCount = response['totalCount']

     
    

    } , () => {
      this._communicationService.showLoading(false);
  
    }, () => {
      this._communicationService.showLoading(false);
      // this.eventsData = response.data
    })
    
  }


  private _subscriptionCallback(): void {
    if (this.subscriptionData && typeof this.subscriptionData) {
      if (this.subscriptionData.notifyComponent === 'app-eventDashboardFooter') {
        switch (this.subscriptionData.action) {
        
            case 'refresh':
              console.log("this.subscriptionData.data",this.subscriptionData.data)
            this.eventId = this.subscriptionData.data.eventId; 
            this.getReservationsStatistics()
            this.getShows()

            break;
         
         
          default:
            break;
        }
      }
    }
    
  }

 

}
