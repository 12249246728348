import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {CommunicationService} from '../../services/communication/communication.service';
import {TranslateService} from '@ngx-translate/core';

import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { environment } from 'src/environments/environment';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { EventsService } from 'src/app/events/events.service';
import { Subscription } from 'rxjs';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { IOption } from 'src/app/modules/form/select/select.interface';
import { ShowsService } from 'src/app/shows/shows.service';
import { EmployeesServices } from 'src/app/employees/employees.service';

@Component({
  selector: 'app-addClientInfo',
  templateUrl: './addClientInfo.component.html',
  styleUrls: ['./addClientInfo.component.scss'],
})
export class AddClientInfoDialogComponent {

  public currentLang;
  public direction;
  public form: FormGroup;
  public translation;
  // public emailRegex = variables.emailRegex;
  public emailPatternErrorMessage;
  public validCaptcha: boolean;
  // public countries = countries;
  public isResponsive: boolean;
  public title = 'Contact Us'
  public siteKey: string = environment.captchaSiteKey; 
  public isLoading : boolean = false; 
  public isVendors : boolean = true;

  public permissionList  = []; 
  public permissionsForm = {} 

  constructor(@Inject(MAT_DIALOG_DATA) public data, 
              private _formBuilder: FormBuilder,
              private _utilitiesService: UtilitiesService,
              private _showsService: ShowsService, 
              private _EmployeesServices : EmployeesServices,
              private _communicationService: CommunicationService,
              private _permissionService: PermissionService,
              private _translate: TranslateService, private _dialog: MatDialog) {


    this.isVendors = this._permissionService.checkIsUserVendor();
    console.log( "isVendors",this.isVendors)
 
  }






  public submitForm() {


    console.log(this.form); 
    this._communicationService.notifyComponent('app-sellingTickets', 'bookSeat' , {
      clientName : this.form.value.clientName, 
      clientPhone : this.form.value.clientPhoneLine,
      clientPhoneCountryCode:this.form.value.clientPhoneCountryCode
    })
    this._dialog.closeAll(); 
  }

  public closeDialog() {
    this._dialog.closeAll(); 
  }

 

  public ngOnInit() {

   
    this.currentLang = this._translate.currentLang;
    this.direction = this.currentLang === 'ar' ? 'rtl' : 'ltr';
    this._translate.getTranslation(this._translate.currentLang).subscribe(translation => {
      console.log("translation events" ,translation)
      this.translation = translation;
    });
   


    this._initializeForm(); 

  }

 
  private _initializeForm(): void {
   
    this.form = this._formBuilder.group({
      clientName : ['',Validators.required], 
      clientPhoneCountryCode : ['KW', Validators.required], 
      clientPhoneLine : ['',Validators.required], 
      clientPhone : [''],
      // numberCountryCode: [''],
      // numberLine: [''],
    });
   
  }
}
