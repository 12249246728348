import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {CommunicationService} from '../../services/communication/communication.service';
import {TranslateService} from '@ngx-translate/core';

import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { environment } from 'src/environments/environment';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { EventsService } from 'src/app/events/events.service';
import { Subscription } from 'rxjs';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { EventDetailsService } from 'src/app/eventDetails/eventDetails.service';
import { IOption } from 'src/app/modules/form/select/select.interface';
import { ShowsService } from 'src/app/shows/shows.service';
import * as moment from 'moment';

@Component({
  selector: 'app-dialog-edit-shows',
  templateUrl: './editShowsForm.component.html',
  styleUrls: ['./editShowsForm.component.scss']
})
export class EditShowsForm {

  public currentLang;
  public direction;
  public form: FormGroup;
  public translation;
  public isResponsive: boolean;
  public isLoading : boolean = false; 
  public isVendors;
  public hoursOptions : IOption[] = []; 
  public minsOptions : IOption[] = []; 
  public meridian : boolean = false ; 
  public currentDate = new Date(); 


  constructor(@Inject(MAT_DIALOG_DATA) public data, 
              private _formBuilder: FormBuilder,
              private _utilitiesService: UtilitiesService,
              private _showsService: ShowsService, 
              private _eventDetailsService: EventDetailsService, 
              private _communicationService: CommunicationService,
              private _permissionService: PermissionService,
              private _translate: TranslateService, private _dialog: MatDialog) {


    this.isVendors = this._permissionService.checkIsUserVendor();
    console.log( this.isVendors)
 
  }

  public generateHours () {

    for(let i =1;i<=12;i++)
      this.hoursOptions.push({
    text : String(i), 
    value : String(i)
    }); 

  }

  public generateMins () {

    for(let i =0;i<60;i+=5)
      this.minsOptions.push({
    text : String(i), 
    value : String(i)
    }); 

  }

  public changeMeridian() {
    this.meridian = !this.meridian
}




  public confirmation(): void {
    this._communicationService.notifyComponent(this.data.notifyComponent, this.data.action, null);
  }

  public submitForm() {
    this._communicationService.showLoading(true) ; 
    console.log(this.data); 
   
    this._showsService.editShow({
      name_en : this.data.form.value.nameEnglish ||  this.data.Data.name_en, 
      name_ar : this.data.form.value.nameArabic ||  this.data.Data.name_ar,  
      date :  this.data.form.value.showDate ? moment.tz(this.data.form.value.showDate, this.data.Data.timezone).locale('en').format('DD MMM YYYY hh:mm A') :   moment.tz(this.data.Data.date, this.data.Data.timezone).locale('en').format('DD MMM YYYY hh:mm A')  , 
      theater :  this.data.Data.theater,
      entranceTime :  this.data.form.value.timeHours ?  `${this.data.form.value.timeHours.value}:${ this.data.form.value.timeMins.value} ${this.meridian ? 'AM' : 'PM'}` :this.data.Data.entranceTime ,
    }, this.data.Data._id).subscribe(res => {
      console.log(res); 
      this._communicationService.showLoading(false) ; 
    this._communicationService.notifyComponent('app-editShowDetailsDialog','close-dialog'); 
    this._communicationService.notifyComponent('app-shows','refresh')
    } )
  //  this._eventDetailsService.editEvents({
  //   name_en : this.data.form.value.englishName  || this.data.eventData.name.en, 
  //   name_ar : this.data.form.value.arabicName  || this.data.eventData.name.ar, 
  //   duration :  this.data.form.value.duration || this.data.eventData.duration,
  //   description_ar : this.data.form.value.descArabic || this.data.eventData.description.ar,
  //   description_en : this.data.form.value.descEnglish || this.data.eventData.description.en,
  //   image : this.data.eventData.image, 
  //   cover : this.data.eventData.cover, 
  //   actors : this.data.form.value.actors || this.data.eventData.actors.map(actor => actor._id)    ,
  //   authors : this.data.form.value.authors  || this.data.eventData.authors.map(author => author._id)  ,
  //   directors : this.data.form.value.directors || this.data.eventData.directors.map(director => director._id)  ,
  // }, this.data.eventData._id).subscribe(response =>{
  //   console.log(response)
  //   this._communicationService.showLoading(false) ; 
  //   this._communicationService.notifyComponent('app-event-details','close-dialog'); 
  //   this._communicationService.notifyComponent('app-event-details','refresh')
  // } )
  }

 

  public ngOnInit() {
    this.currentLang = this._translate.currentLang;
    this.direction = this.currentLang === 'ar' ? 'rtl' : 'ltr';
    this._translate.getTranslation(this._translate.currentLang).subscribe(translation => {
      console.log("translation events" ,translation)
      this.translation = translation;
    });

    this.generateHours(); 
    this.generateMins(); 
    console.log(this.data)
   


    this._initializeForm(); 
  }




  private _initializeForm(): void {
    this.form = this._formBuilder.group({
      englishName: ['', [Validators.required]],
      arabicName: ['', [Validators.required]],
      descEnglish: ['',  [Validators.required]],
      descArabic: ['',  [Validators.required]],
      duration: ['',  [Validators.required]],
      actors: ['', [Validators.required]], 
      authors: ['', [Validators.required]],
      directors: ['', [Validators.required]]
    });
   
  }
}
