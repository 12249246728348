import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {CommunicationService} from '../../services/communication/communication.service';
import {TranslateService} from '@ngx-translate/core';

import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { environment } from 'src/environments/environment';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { EventsService } from 'src/app/events/events.service';
import { Subscription } from 'rxjs';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { IOption } from 'src/app/modules/form/select/select.interface';
import { ShowsService } from 'src/app/shows/shows.service';
import { ReservationServices } from 'src/app/reservations/reservations.service';
import {saveAs} from 'file-saver'
import { ExchangeDialogComponent } from '../exchange-dialog/exchange-dialog.component';

@Component({
  selector: 'client-app-dialog-reservation-details',
  templateUrl: './client-reservations-details-dialog.component.html',
  styleUrls: ['./client-reservations-details-dialog.component.scss'],
})
export class ClientReservationsDetailsDialogComponent {
  public currentLang;
  public direction;
  public form: FormGroup;
  public translation;
  // public emailRegex = variables.emailRegex;
  public emailPatternErrorMessage;
  public validCaptcha: boolean;
  // public countries = countries;
  public isResponsive: boolean;
  public title = 'Contact Us'
  public siteKey: string = environment.captchaSiteKey;
  public crewTypes :string[] = ['actors', 'authors', 'directors'] ; 
  public crews  = {} ; 
  public isLoading : boolean = false; 
  public isVendors : boolean = true;
  public hoursOptions : IOption[] = []; 
  public minsOptions : IOption[] = []
  public currentDate = new Date(); 
  public meridian : boolean ; 

  constructor(@Inject(MAT_DIALOG_DATA) public data, 
              private _ReservationServices: ReservationServices, 
              private _communicationService: CommunicationService,
              private _permissionService: PermissionService,
              private _translate: TranslateService, private _dialog: MatDialog) {


    this.isVendors = this._permissionService.checkIsUserVendor();
 
  }



  public closeCreateShow() {
    this._dialog.closeAll(); 
  }

  public copyId() {
    
    const copyText = document.getElementById('textId')
    navigator.clipboard.writeText(copyText.innerText);
  }


  public formatDate(date,format='') {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' , hour : 'numeric', minute:'numeric'};
    const dateObj = new Date(date); 

    return dateObj.toLocaleDateString(this.currentLang ,options)
  }
 

  public ngOnInit() {

    this.currentLang = this._translate.currentLang;
    this.direction = this.currentLang === 'ar' ? 'rtl' : 'ltr';
    this._translate.getTranslation(this._translate.currentLang).subscribe(translation => {
      console.log("translation events" ,translation)
      this.translation = translation;
    });

  }




 
  
}
