// export const environment = {
//   production: false,
//   url: 'https://alballambookingapi.eurisko.me/',
//   appUrl: 'http://localhost:4200/',
//   oneSignalAppId: '1d39dd84-9454-4b72-bda6-471902523345',
//   captchaSiteKey: '6LeKty4UAAAAAE2GW7DUVvgprXLYxJhaZJAm_Fe_',
//   outlookClientId: 'e54f9592-30ec-47b7-bf92-43233450e3fc',
//   googleCalendarObj: {
//     apiKey: 'AIzaSyDbVOmmWqE03NwVKl9RCResmBUVlbJTG9k',
//     clientId: '510934446104-v4gtjro6aab6iot391mdsq00gr4mm8li.apps.googleusercontent.com',
//     project_id: 'alballam-booking-system',
//     auth_uri: 'https://accounts.google.com/o/oauth2/auth',
//     token_uri: 'https://oauth2.googleapis.com/token',
//     auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
//     redirect_uris: ['https://alballambooking.eurisko.me'],
//     javascript_origins: ['http://localhost', 'http://localhost:4200',
//       'https://alballam-booking-system.firebaseapp.com', 'https://alballambooking.eurisko.me'],
//     discoveryDocs: [
//       'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
//     scope: 'https://www.googleapis.com/auth/calendar',
//     fetch_basic_profile: true,
//     redirect_uri: 'http://localhost:4200/'
//   }
// };

export const environment = {
  production: false,
  url: 'https://api.alballambooking.com/',
  customerUrl : ' https://vendor-api-backend.onrender.com', 
  appUrl: 'http://localhost:4200/',   
  oneSignalAppId: '1d39dd84-9454-4b72-bda6-471902523345',
  captchaSiteKey: '6LdahQIeAAAAAAKPWkFEr2oIXDHewoXRi3R4YQ3G',
  outlookClientId: 'e54f9592-30ec-47b7-bf92-43233450e3fc',
  googleCalendarObj: {
    apiKey: 'AIzaSyDbVOmmWqE03NwVKl9RCResmBUVlbJTG9k',
    clientId: '510934446104-v4gtjro6aab6iot391mdsq00gr4mm8li.apps.googleusercontent.com',
    project_id: 'alballam-booking-system',
    auth_uri: 'https://accounts.google.com/o/oauth2/auth',
    token_uri: 'https://oauth2.googleapis.com/token',
    auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
    redirect_uris: ['https://alballambooking.eurisko.me'],
    javascript_origins: ['http://localhost', 'http://localhost:4200',
      'https://alballam-booking-system.firebaseapp.com', 'https://alballambooking.eurisko.me'],
    discoveryDocs: [
      'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
    scope: 'https://www.googleapis.com/auth/calendar',
    fetch_basic_profile: true,
    redirect_uri: 'https://alballambooking.eurisko.me/'
  }
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
