import {Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {environment} from '../../environments/environment';
import {variables} from '../app.variables';
import {ApiService} from '../services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor(private _apiService: ApiService) {
  }

  public getEvents(searchTerm : string,lang:string) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let url = environment.url + variables.events.getEvents;
  
      this._apiService.sendApi('get', url,
        {search :searchTerm , searchFields : lang === 'ar' ? 'name_ar' : 'name_en' }, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

  public getCrews(type : string) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let url = environment.url + variables.events.getCrews + `/${type}`;
  
      this._apiService.sendApi('get', url,
        { }, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

  public createEvents(body) {

    return new Observable(userInfoObservable => {
      let apiResponse;
      let url = environment.url + variables.events.createEvents;
      console.log("body",body)
      this._apiService.sendApi('post', url,
      body, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }
}
