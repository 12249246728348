import {Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {environment} from '../../environments/environment';
import {variables} from '../app.variables';
import {ApiService} from '../services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class EventDetailsService {

  constructor(private _apiService: ApiService) {
  }

  public getEventDetails(eventId : string) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let url = environment.url + variables.customerApis.getShowDetails.replace(':showID',eventId);
  
      this._apiService.sendApi('get', url,
        {}, false, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

  public getVendorZones() {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let url = environment.url + variables.events.getVendorZones 
  
      this._apiService.sendApi('get', url,
        {}, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }


  public editEvents(body, eventId : string) {

    return new Observable(userInfoObservable => {
      let apiResponse;
      let url = environment.url + variables.events.editEvents + `?_id=${eventId}`;
      console.log("body",body)
      this._apiService.sendApi('put', url,
      body, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

  public getPaymentMethods() {

    return new Observable(userInfoObservable => {
      let apiResponse;
      let url = environment.url + variables.events.paymentMethods 
  
      this._apiService.sendApi('get', url,
        {}, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

 
}
