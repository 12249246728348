import {Component, Inject, Input, Output, EventEmitter} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {CommunicationService} from '../../services/communication/communication.service';
import {TranslateService} from '@ngx-translate/core';

import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { environment } from 'src/environments/environment';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { EventsService } from 'src/app/events/events.service';
import { Subscription } from 'rxjs';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { IOption } from 'src/app/modules/form/select/select.interface';
import { ShowsService } from 'src/app/shows/shows.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-menuBtn',
  templateUrl: './menuBtn.component.html',
  styleUrls: ['./menuBtn.component.scss'],
})
export class MenuBtnComponent {

  @Input() public eventData;
  public currentEventData ; 
  public currentLang;
  public direction;
  public form: FormGroup;
  public translation;

  public isResponsive: boolean;
  public title = 'Contact Us'
  public siteKey: string = environment.captchaSiteKey;
  public crewTypes :string[] = ['actors', 'authors', 'directors'] ; 
  public crews  = {} ; 
  public isLoading : boolean = false; 
  public isVendors : boolean = true;
  public eventsData : [] ; 
  public currentEventId ; 
 

  constructor(
              private _formBuilder: FormBuilder,
              private _utilitiesService: UtilitiesService,
              private _showsService: ShowsService, 
              private _eventsService: EventsService,
              private _communicationService: CommunicationService,
              private _permissionService: PermissionService,
              private _UtilitiesService : UtilitiesService, 
              private _router : ActivatedRoute, 
              private _translate: TranslateService, private _dialog: MatDialog) {


    this.isVendors = this._permissionService.checkIsUserVendor();
    console.log( "isVendors",this.isVendors)

    this._router.params.subscribe(params => {
      console.log("_router", params)
      this.currentEventId = params['eventId']
    })
 
  }







  public ngOnInit() {

    this.currentLang = this._translate.currentLang;
    this.direction = this.currentLang === 'ar' ? 'rtl' : 'ltr';
    this._translate.getTranslation(this._translate.currentLang).subscribe(translation => {
      console.log("translation events" ,translation)
      this.translation = translation;
    });

    this.getEventList()


  


  }

  public openSidebar() {
    console.log("testmhoba")
  }

  public redirectToEventDetails(eventId : string,event) {

    console.log("eventId", eventId)
    const name = this.currentLang === 'ar' ? event.name_ar : event.name_en; 
    //@ts-ignore
    this.currentEventData = this.eventsData.find(item => item._id === eventId)
   
    this._UtilitiesService.routeToEventDashboard(eventId,  event.name_en,event.name_ar)
  
    this._communicationService.notifyComponent('app-eventDashboardFooter','refresh',{eventId})

  }

  public getEventList() {
    this._communicationService.showLoading(true);
    this._eventsService.getEvents('',this.currentLang).subscribe(response => {
      this.eventsData = response['data']; 
      console.log("currentEventId",this.currentEventId)
      //@ts-ignore
      this.currentEventData = this.eventsData.find(item => item._id === this.currentEventId)
      console.log("eventSData", this.currentEventData)
    }  , () => {
      this._communicationService.showLoading(false);
      this.eventsData =[] ; 
    }, () => {
      this._communicationService.showLoading(false);
      // this.eventsData = response.data
    })

  }




 

}
