import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {CommunicationService} from '../../services/communication/communication.service';
import {TranslateService} from '@ngx-translate/core';
import { FormBuilder, Validators } from '@angular/forms';
import { EditShowsForm } from '../edit-shows-form/editShowsForm.component';
import { ShowsService } from 'src/app/shows/shows.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-editShowDetailsDialog',
  templateUrl: './editShowDetailsDialog.component.html',
  styleUrls: ['./editShowDetailsDialog.component.scss']
})
export class EditShowDetailsDialog {

  public currentLang;
  public direction;
  public translation; 
  public editShowForm;
  public subscription; 
  public subscriptionData; 
  constructor(@Inject(MAT_DIALOG_DATA) public data, private _communicationService: CommunicationService,
              private _translate: TranslateService,
              private _ShowsService: ShowsService, 
              private _formBuilder: FormBuilder,
              private _dialog: MatDialog) {
                this.currentLang = this._translate.currentLang;
                this.direction = this.currentLang === 'ar' ? 'rtl' : 'ltr';
                this._translate.getTranslation(this._translate.currentLang).subscribe(translation => {
                  console.log("translation events" ,translation)
                  this.translation = translation;
                });

                this.subscription = this._communicationService.getData().subscribe(response => {
                  this.subscriptionData = response;
                  this._subscriptionCallback();
                });

                console.log(" this.data.showData", this.data.showData)
          
  }

  public prepareEditDialogData () {
    

    this.editShowForm = {
      time : {
        form : this._formBuilder.group({
          timeHours: [this.data.showData.entranceTime.split(":")[0][0] == '0' ?this.data.showData.entranceTime.split(":")[0][1] : this.data.showData.entranceTime.split(":")[0] , [Validators.required]],
          timeMins:  [this.data.showData.entranceTime.split(":")[1] == '00'? '0' : this.data.showData.entranceTime.split(":")[1], [Validators.required]]
        }), 
        Data : this.data.showData,
        isTimePicker : true , 
        timeHours : {
        title : this.translation.SHOWS.HH ,
        id : 'timeHours', 
        label : this.translation.SHOWS.HH,
        name : 'timeHours', 
      },
      timeMins : {
        title :this.translation.SHOWS.MM ,
        id : 'timeMins', 
        label : this.translation.SHOWS.MM,
        name : 'timeMins',
      },
    }, 
    datePicker : {
      title :this.translation.SHOWS.date  ,
      id : 'showDate', 
      label : this.translation.SHOWS.date ,
      name : 'showDate', 
      form : this._formBuilder.group({
        showDate: [this.data.showData.date , [Validators.required]],
      }), 
      Data : this.data.showData,
      isDatePicker : true , 
    }, 
    showName : {
      form : this._formBuilder.group({
        nameArabic: [this.data.showData.name_ar , [Validators.required]],
        nameEnglish:  [this.data.showData.name_en, [Validators.required]]
      }), 
      Data : this.data.showData,
      isText : true , 
      nameArabic : {
      title : this.translation.SHOWS.nameArabic  ,
      id : 'nameArabic', 
      label : this.translation.SHOWS.nameArabic ,
      name : 'nameArabic', 
    },  
    nameEnglish : {
      title : this.translation.SHOWS.nameEnglish  ,
      id : 'nameEnglish', 
      label :  this.translation.SHOWS.nameEnglish  ,
      name : 'nameEnglish',
    },
  }, 
     
    }

  }

  public deleteShow () {
    this._communicationService.showLoading(true);
    this._ShowsService.deleteShow(this.data.showData._id)
    .subscribe(res => {
      this._communicationService.showLoading(false);
      this._communicationService.notifyComponent('app-editShowDetailsDialog','close-dialog'); 
      this._communicationService.notifyComponent('app-shows','refresh')
    })
  }

  public activateShow() {
    this._communicationService.showLoading(true);
    this._ShowsService.activateShow( [this.data.showData._id])
    .subscribe(res => {
      this._communicationService.showLoading(false);
      this._communicationService.notifyComponent('app-editShowDetailsDialog','close-dialog'); 
      this._communicationService.notifyComponent('app-shows','refresh')
    })
  }

  public deactivateShow() {
    this._communicationService.showLoading(true);
    this._ShowsService.deactivateShow( [this.data.showData._id])
    .subscribe(res => {
      this._communicationService.showLoading(false);
      this._communicationService.notifyComponent('app-editShowDetailsDialog','close-dialog'); 
      this._communicationService.notifyComponent('app-shows','refresh')
    })
  }

  public showEditForm(fieldName) {
    this.prepareEditDialogData(); 
    console.log("this.editShowForm[fieldName]", this.editShowForm[fieldName])
    this._dialog.open(EditShowsForm, {
      data: this.editShowForm[fieldName], 
    });

  }

  public confirmation(): void {
    this._communicationService.notifyComponent(this.data.notifyComponent, this.data.action, null);
  }

  private _subscriptionCallback(): void {
    if (this.subscriptionData && typeof this.subscriptionData) {
      if (this.subscriptionData.notifyComponent === 'app-editShowDetailsDialog') {
        switch (this.subscriptionData.action) {
          case 'close-dialog':
            this._dialog.closeAll();
            break;
         
          default:
            break;
        }
      }
    }
    
  }

 
}
