import {Component, ElementRef, HostListener, OnInit} from '@angular/core';


import {TranslateService} from '@ngx-translate/core';

import {MatDialog} from '@angular/material';

import { OwlOptions } from 'ngx-owl-carousel-o';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { CommunicationService } from 'src/app/services/communication/communication.service';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { DefaultLayoutService } from 'src/app/layout/default-layout/default-layout.service';


@Component({
  selector: 'app-sideBar',
  templateUrl: './sideBar.component.html',
  styleUrls: ['./sideBar.component.scss']
})
export class SideBarComponent implements OnInit {
  public faqs;
  public currentLang;
  public direction;
  public translation;
  public customOptions : OwlOptions;
  public isUserLoggedIn = false; 
  public subscription; 
  public subscriptionData; 
  public isVendors; 
  public toggle = false; 
  public defaultLanguage; 
  public languages; 

  constructor(private _communicationService: CommunicationService,
              private _utilitiesService: UtilitiesService,
              private _permissionService: PermissionService,
              private _defaultLayoutService : DefaultLayoutService, 
              private eRef: ElementRef,
              private _translate: TranslateService, private _dialog: MatDialog) {
    this.currentLang = this._translate.currentLang;
    this.direction = this.currentLang === 'ar' ? 'rtl' : 'ltr';
   


    this.subscription = this._communicationService.getData().subscribe(response => {
      this.subscriptionData = response;
      this._subscriptionCallback();
    });

    this.isVendors = this._permissionService.checkIsUserVendor();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(this.eRef.nativeElement.contains(event.target)) {
      console.log("inside")
    } else {
      if(event.target.id !== 'li-bar' && event.target.id !== 'li-icon')
      {
       if (this.currentLang === 'ar')
        document.getElementById("sideNav-holder").style.right= "-1000px";
      else 
        document.getElementById("sideNav-holder").style.left= "-1000px";
          this._communicationService.notifyComponent('app-userNavbar', 'toggle' ,{ toggleNav :false})
          
      }
      
      // else 
      // {
      //   document.getElementById("sideNav-holder").style.left= "-1000px";
      // }
      // console.log("outside")
    }
  }

  public ngOnInit() {
    this._translate.getTranslation(this._translate.currentLang).subscribe(translation => this.translation = translation,
      err => null, () => {
        this._utilitiesService.setPageTitle(this.translation.TOP_BAR.FAQs);
        
      });

      this.isUserLoggedIn = this._permissionService.checkUserLoggedIn()
      this.languages = this._utilitiesService.getLanguageArray();
      this.initializeArray();

  }

  public back(): void {
    history.back();
  }

  public logoutUser() {
    const platformID = StorageService.getItem('platformID');
    this._communicationService.showLoading(true);
    //@ts-ignore
    this._defaultLayoutService.logout({deviceID: platformID}).subscribe(response => this.logoutResponse = response,
      () => {
        StorageService.deleteAuthenticationData();
        // this._communicationService.notifyComponent('app-footer', 'logout');
        // this._communicationService.notifyComponent('app-default-layout', 'updateVendors');
        this._utilitiesService.routeToLogin();
        this._communicationService.showLoading(false);
        // this.isAuthenticated = false;
        // this.isVendors = false;
        this._communicationService.notifyComponent('app-mediaQueryScreen', 'refresh');
        this.isUserLoggedIn = this._permissionService.checkUserLoggedIn()
        this.isVendors = this._permissionService.checkIsUserVendor();
        document.getElementById("sideNav-holder").style.left= "-1000px";
      },
      () => {
        StorageService.deleteAuthenticationData();
        // this._communicationService.notifyComponent('app-footer', 'logout');
        this._communicationService.showLoading(false);
        // this.isAuthenticated = false;
        // this.isVendors = false;
        this._communicationService.notifyComponent('app-dashboard', 'updateAuthentication', null);
        this._communicationService.notifyComponent('app-default-layout', 'updateVendors');
        this._communicationService.notifyComponent('app-mediaQueryScreen', 'refresh');
        this._utilitiesService.routeToDashboard();
        this.isUserLoggedIn = this._permissionService.checkUserLoggedIn()
        this.isVendors = this._permissionService.checkIsUserVendor();
        document.getElementById("sideNav-holder").style.left= "-1000px";
      }
    );
  }


  // public showAlert(title: string, content: string): void {
  //   const dialogId = new Date();
  //   this._communicationService.setLatestDialogId(dialogId);
  //   this._dialog.open(DialogComponent, {
  //     data: {
  //       title,
  //       content,
  //       isConfirmationPopUp: false
  //     }
  //   });
  // }
  
  public routeToLogin() {
    this._utilitiesService.routeToLogin();
    if (this.currentLang === 'ar')
      document.getElementById("sideNav-holder").style.right= "-1000px";
    else 
      document.getElementById("sideNav-holder").style.left= "-1000px";
    this._communicationService.notifyComponent('app-userNavbar', 'toggle' ,{ toggleNav :false})
    
  }

  public routeToSignUp() {
    this._utilitiesService.routeTosignUp(); 
    if (this.currentLang === 'ar')
    document.getElementById("sideNav-holder").style.right= "-1000px";
  else 
    document.getElementById("sideNav-holder").style.left= "-1000px";
    this._communicationService.notifyComponent('app-userNavbar', 'toggle' ,{ toggleNav :false})
  
  }

  public routeToDashboard() {
    this._utilitiesService.routeToDashboard()
    if (this.currentLang === 'ar')
      document.getElementById("sideNav-holder").style.right= "-1000px";
    else 
      document.getElementById("sideNav-holder").style.left= "-1000px";
    this._communicationService.notifyComponent('app-userNavbar', 'toggle' ,{ toggleNav :false})
  
  }
  public routeToDashboardSearch() {
    this._utilitiesService.routeToDashboardSearch()
    window.scroll(0,0)
    if (this.currentLang === 'ar')
    document.getElementById("sideNav-holder").style.right= "-1000px";
  else 
    document.getElementById("sideNav-holder").style.left= "-1000px";
    this._communicationService.notifyComponent('app-userNavbar', 'toggle' ,{ toggleNav :false})
  }


  public routeToContact() {
    this._utilitiesService.routeToContact()
    if (this.currentLang === 'ar')
      document.getElementById("sideNav-holder").style.right= "-1000px";
    else 
      document.getElementById("sideNav-holder").style.left= "-1000px";
    this._communicationService.notifyComponent('app-userNavbar', 'toggle' ,{ toggleNav :false})
  }
  

  public routeToPolicy() {
    this._utilitiesService.routeToPolicy()
    if (this.currentLang === 'ar')
      document.getElementById("sideNav-holder").style.right= "-1000px";
    else 
      document.getElementById("sideNav-holder").style.left= "-1000px";
    this._communicationService.notifyComponent('app-userNavbar', 'toggle' ,{ toggleNav :false})
  }

  public routeToMyTicket() {
    this._utilitiesService.routeToMyTicket()
    if (this.currentLang === 'ar')
      document.getElementById("sideNav-holder").style.right= "-1000px";
    else 
      document.getElementById("sideNav-holder").style.left= "-1000px";
    this._communicationService.notifyComponent('app-userNavbar', 'toggle' ,{ toggleNav :false})
  }





  // private _handleErrors(error) {
  //   switch (error.status) {
  //     default:
  //       this.showAlert(this.translation.GENERAL_ERRORS.INTERNAL_ERROR_TITLE, this.translation.GENERAL_ERRORS.INTERNAL_ERROR_TEXT);
  //       break;
  //   }
  // }

  private _subscriptionCallback(): void {
    if (this.subscriptionData && typeof this.subscriptionData) {
      if (this.subscriptionData.notifyComponent === 'app-sideBar') {
        switch (this.subscriptionData.action) {
          case 'logout':  
            this.logoutUser();
            break;
          case 'updateProfile':
            this.isUserLoggedIn = this._permissionService.checkUserLoggedIn()
            break;
          case 'toggle' :   
          console.log("this.subscriptionData",this.subscriptionData)
            this.toggle = this.subscriptionData.data.toggleNav
          break; 
          default:
            break;
        }
      }
    }
  }
  public initializeArray() {
    for (const lang of this.languages) {
      if (lang.id === this.currentLang) {
        this.defaultLanguage = lang;
      }
    }
  }
  public changeLanguage(language) {
    let newLanguage='en'
    if(this.defaultLanguage.id==='en')
      newLanguage='ar'
    
      
    StorageService.storeLanguageData(newLanguage);
    this._utilitiesService.changeLanguage();
    if (this.currentLang === 'ar')
      document.getElementById("sideNav-holder").style.right= "-1000px";
    else 
      document.getElementById("sideNav-holder").style.left= "-1000px";
    window.location.reload();
  }


}
