import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {variables} from '../app.variables';
import {ApiService} from '../services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class ZoneManageService{

  constructor(private _apiService: ApiService) {
  }

  public createZone(id :string , zoneData) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let endPoint =  variables.zoneManage.postZone + `?id=${id}`
      let url = environment.url + endPoint;
  
      this._apiService.sendApi('post', url,
      zoneData, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

  public editZone(id :string , zoneData, zone_id : string) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let endPoint =  variables.zoneManage.postZone + `?id=${id}&_id=${zone_id}`
      let url = environment.url + endPoint;
  
      this._apiService.sendApi('put', url,
      zoneData, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

  public deleteZone(zone_id : string) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let endPoint =  variables.zoneManage.postZone + `?_id=${zone_id}`
      let url = environment.url + endPoint;
  
      this._apiService.sendApi('delete', url,
      {}, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }


  public getMapBySeatPlanId(seatPlanId :string) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let endPoint =  variables.zoneManage.getMapBySeatPlanId + `?id=${seatPlanId}`
      let url = environment.url + endPoint;
  
      this._apiService.sendApi('get', url,
      {}, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });
  }
  public getZonesByPlay(playId :string) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let endPoint =  variables.zoneManage.getZonesByPlay + `?_id=${playId}`
      let url = environment.url + endPoint;
  
      this._apiService.sendApi('get', url,
      {}, false, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });
  }

  public updateSeatZone(seatPlanId :string, body) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let endPoint =  variables.zoneManage.updateSeatZone + `?id=${seatPlanId}`
      let url = environment.url + endPoint;
  
      this._apiService.sendApi('put', url,
      body, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });
  }

}
