import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommunicationService} from '../services/communication/communication.service';

import {TranslateService} from '@ngx-translate/core';
import {DialogComponent} from '../components/dialog/dialog.component';
import {MatDialog} from '@angular/material';
import {UtilitiesService} from "../services/utilities.service";
import { OwlOptions } from 'ngx-owl-carousel-o';

import * as moment from 'moment';


@Component({
  selector: 'app-ticketsMedia',
  templateUrl: './ticketsMedia.component.html',
  styleUrls: ['./ticketsMedia.component.scss']
})
export class TicketsMediaComponent implements OnInit {
  public faqs;
  public currentLang;
  public direction;
  public translation;
  public customOptions : OwlOptions;
  public tickets = []; 
  @Input() showDetailsData; 
  @Input() selectedTimes;
  @Input() showsData;
  @Input() showId;
  @Input() selectedShow;
  @Input() selectedShowTheater;

  @Output() public eventEmitter = new EventEmitter();
  constructor(private _communicationService: CommunicationService,
              private _utilitiesService: UtilitiesService,
              private _translate: TranslateService, private _dialog: MatDialog) {
    this.currentLang = this._translate.currentLang;
    this.direction = this.currentLang === 'ar' ? 'rtl' : 'ltr';
  
  }

  public ngOnInit() {
    // this._translate.getTranslation(this._translate.currentLang).subscribe(translation => this.translation = translation,
    //   err => null, () => {
    //     this._utilitiesService.setPageTitle(this.translation.TOP_BAR.FAQs);
        
    //   });
      this.currentLang = this._translate.currentLang;
      this.direction = this.currentLang === 'ar' ? 'rtl' : 'ltr';
      this._translate.getTranslation(this._translate.currentLang).subscribe(translation => {
        console.log("translation events" ,translation)
        this.translation = translation;
       
      });

      
  }

  public back(): void {
    history.back();
  }

  public showAlert(title: string, content: string): void {
    const dialogId = new Date();
    this._communicationService.setLatestDialogId(dialogId);
    this._dialog.open(DialogComponent, {
      data: {
        title,
        content,
        isConfirmationPopUp: false
      }
    });
  }

  public redirectToBuyTicket() {
   
    this._utilitiesService.routeToBuyTicket(this.showId, this.selectedShow)
  }

  public selectShowMedia(showId) {
    this.eventEmitter.emit(showId)
  }

  private _handleErrors(error) {
    switch (error.status) {
      default:
        this.showAlert(this.translation.GENERAL_ERRORS.INTERNAL_ERROR_TITLE, this.translation.GENERAL_ERRORS.INTERNAL_ERROR_TEXT);
        break;
    }
  }
}
