import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {CommunicationService} from '../../services/communication/communication.service';
import {TranslateService} from '@ngx-translate/core';

import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { environment } from 'src/environments/environment';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { EventsService } from 'src/app/events/events.service';
import { Subscription } from 'rxjs';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { IOption } from 'src/app/modules/form/select/select.interface';
import { ShowsService } from 'src/app/shows/shows.service';
import { EmployeesServices } from 'src/app/employees/employees.service';
import { ZoneManageService } from 'src/app/zoneManagement/zoneManagement.service';

@Component({
  selector: 'app-createZoneDialog',
  templateUrl: './createZoneDialog.component.html',
  styleUrls: ['./createZoneDialog.component.scss'],
})
export class CreateZoneDialogComponent {

  public currentLang;
  public direction;
  public form: FormGroup;
  public translation;
  // public emailRegex = variables.emailRegex;
  public emailPatternErrorMessage;
  public validCaptcha: boolean;
  // public countries = countries;
  public isResponsive: boolean;
  public title = 'Contact Us'
  public siteKey: string = environment.captchaSiteKey; 
  public isLoading : boolean = false; 
  public isVendors : boolean = true;

  public permissionList  = []; 
  public permissionsForm = {} 

  constructor(@Inject(MAT_DIALOG_DATA) public data, 
              private _formBuilder: FormBuilder,
              private _utilitiesService: UtilitiesService,
              private _showsService: ShowsService, 
              private _EmployeesServices : EmployeesServices,
              private _communicationService: CommunicationService,
              private _permissionService: PermissionService,
              private _ZoneManageService : ZoneManageService, 
              private _translate: TranslateService, private _dialog: MatDialog) {


    this.isVendors = this._permissionService.checkIsUserVendor();
    console.log( "isVendors",this.isVendors)
 
  }






  public submitForm() {


    console.log("submit" , this.form)

    const zoneData = {
      name_en: this.form.value.zoneNameEnglish,
      name_ar: this.form.value.zoneNameArabic,
      color: this.form.value.zoneColor,
      price: this.form.value.zonePrice.replace(',','')
    }
    this._communicationService.showLoading(true);
    if (!this.data.zoneData)
      this._ZoneManageService.createZone(this.data.seatPlanId,zoneData).subscribe(res => {
        this._communicationService.showLoading(false);
        this._dialog.closeAll(); 
        this._communicationService.notifyComponent('app-zoneManagement','refresh')
      })
    else 
    {
      this._ZoneManageService.editZone(this.data.seatPlanId,zoneData,this.data.zoneData._id).subscribe(res => {
        this._communicationService.showLoading(false);
        this._dialog.closeAll(); 
        this._communicationService.notifyComponent('app-zoneManagement','refresh')
      })
    }
  
   
  }

  public closeZone() {
    this._dialog.closeAll(); 
  }

  public deleteZone() {
    this._ZoneManageService.deleteZone(this.data.zoneData._id).subscribe(res => {
      this._communicationService.notifyComponent('app-zoneManagement','refresh')
    })
  }



  public ngOnInit() {

    
    this.currentLang = this._translate.currentLang;
    this.direction = this.currentLang === 'ar' ? 'rtl' : 'ltr';
    this._translate.getTranslation(this._translate.currentLang).subscribe(translation => {
      console.log("translation events" ,translation)
      this.translation = translation;
    });
   
    this._initializeForm(); 

  }

 
  private _initializeForm(): void {
    console.log(this.data)
    this.form = this._formBuilder.group({
      zoneNameEnglish: [this.data.zoneData ? this.data.zoneData.label : '' , [Validators.required]],
      zoneNameArabic: [this.data.zoneData ? this.data.zoneData.label : '' , [Validators.required]],
      zoneColor : [ this.data.zoneData ? this.data.zoneData.color : ''  , [Validators.required]], 
      zonePrice : [  this.data.zoneData ? this.data.zoneData.price : ''  , [Validators.required]], 
    
    });
    console.log(" this.form ", this.form.get('zoneColor') )
   
  }
}

