import {Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {environment} from '../../environments/environment';
import {variables} from '../app.variables';
import {ApiService} from '../services/api/api.service';
import { StorageService } from '../services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ReservationServices {

  constructor(private _apiService: ApiService) {
  }

  public getReservations(page : number , limit :number ,eventId :string, filters ) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let endPoint =  variables.reservations.getReservations.replace(':showID', eventId); 
      let url = environment.url + endPoint;
  
      this._apiService.sendApi('get', url,
    {page, limit , ...filters}, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

  public enterReservation(bookingId :  string ) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let endPoint =  variables.reservations.enterReservation; 
      let url = environment.url + endPoint;
  
      this._apiService.sendApi('post', url,
      {bookingId}, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

  public cancelReservation(ticketID :  string[] ) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let endPoint =  variables.reservations.cancelReservation; 
      let url = environment.url + endPoint;
  
      this._apiService.sendApi('post', url,
      {users : ticketID}, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

  public sendEmail( ticketID:string) {

    return new Observable(userInfoObservable => {
      let apiResponse;
      let endPoint =  variables.reservations.sendEmail.replace(':ticketID', ticketID); ; 
      let url = environment.url + endPoint;
  
      this._apiService.sendApi('post', url,
      {}, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

  public downloadandprint(ticketID :  string ) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let endPoint =  variables.reservations.downloadTicket.replace(':ticketID', ticketID); ; 
      let url = environment.url + endPoint;
  
      this._apiService.sendApi('get', url,{}, true,false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

  //@ts-ignore
  public exchange( showID:string, data :Any) {

    return new Observable(userInfoObservable => {
      let apiResponse;
      let endPoint =  variables.reservations.exchange + `?id=${showID}` ; 
      let url = environment.url + endPoint;
  
      this._apiService.sendApi('post', url,
      data, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

  public bookReservedTicket(ticketID :  string[] ,playId:string) {
    return new Observable(userInfoObservable => {
      let apiResponse;
      let endPoint =  variables.reservations.bookReservedTicket; 
      let url = environment.url + endPoint+`?id=${playId}`;
  
      this._apiService.sendApi('post', url,
      {users : ticketID}, true, false)
        .subscribe(response => (apiResponse = response),
          error => {
            userInfoObservable.error(error);
          },
          () => {
            userInfoObservable.next(apiResponse);
            userInfoObservable.complete();
          }
        );
    });

  }

 
}
