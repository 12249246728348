import {Component, OnInit} from '@angular/core';
import {CommunicationService} from '../services/communication/communication.service';

import {TranslateService} from '@ngx-translate/core';
import {DialogComponent} from '../components/dialog/dialog.component';
import {MatDialog} from '@angular/material';
import {UtilitiesService} from "../services/utilities.service";
import { OwlOptions } from 'ngx-owl-carousel-o';
import { StorageService } from '../services/storage/storage.service';
import { PermissionService } from '../services/permission/permission.service';


@Component({
  selector: 'app-userNavbar',
  templateUrl: './userNavbar.component.html',
  styleUrls: ['./userNavbar.component.scss']
})
export class UserNavbarComponent implements OnInit {
  public faqs;
  public currentLang;
  public direction;
  public translation;
  public customOptions : OwlOptions;
  public languages; 
  public defaultLanguage; 
  public toggleNav = false
  public isVendors ; 
  public subscription; 
  public subscriptionData; 
  constructor(private _communicationService: CommunicationService,
              private _utilitiesService: UtilitiesService,
              private _permissionService : PermissionService,
              private _translate: TranslateService, private _dialog: MatDialog) {
    this.currentLang = this._translate.currentLang;
    this.direction = this.currentLang === 'ar' ? 'rtl' : 'ltr';

    this.isVendors = this._permissionService.checkIsUserVendor();
    this.customOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: false,
      margin: 40,
      pullDrag: false,
      dots: false,
      autoplay: true ,
      autoplayTimeout: 1000,
      navSpeed: 700,
      navText: ['', ''],
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: 2
        },
        740: {
          items: 3
        },
        940: {
          items: 4
        }
      },
     
    }

    this.subscription = this._communicationService.getData().subscribe(response => {
      this.subscriptionData = response;
      this._subscriptionCallback();
    });
  
  }

  public ngOnInit() {
   
    this.currentLang = this._translate.currentLang;
    this.direction = this.currentLang === 'ar' ? 'rtl' : 'ltr';
   
    this._translate.getTranslation(this._translate.currentLang).subscribe(translation => this.translation = translation,
      err => null, () => {
        this._utilitiesService.setPageTitle(this.translation.TOP_BAR.FAQs);
        
      });
      this.languages = this._utilitiesService.getLanguageArray();
      this.initializeArray();
  }

  public initializeArray() {
    for (const lang of this.languages) {
      if (lang.id === this.currentLang) {
        this.defaultLanguage = lang;
      }
    }
  }

  public changeLanguage(language) {
    StorageService.storeLanguageData(language.id);
    this._utilitiesService.changeLanguage();
    window.location.reload();
  }
  
  public toggleSideNav() {
    if (!this.toggleNav)
    {
      if (this.currentLang === 'ar')
      document.getElementById("sideNav-holder").style.right= "0px";
    else 
      document.getElementById("sideNav-holder").style.left= "0px";
      this.toggleNav = true ; 
      this._communicationService.notifyComponent('app-sideBar', 'toggle' ,{ toggleNav : this.toggleNav})
    }
    else 
    {
      if (this.currentLang === 'ar')
      document.getElementById("sideNav-holder").style.right= "-1000px";
    else 
      document.getElementById("sideNav-holder").style.left= "-1000px";
      this.toggleNav = false ; 
        this._communicationService.notifyComponent('app-sideBar', 'toggle' ,{ toggleNav : this.toggleNav})
    }
    
  }

  public back(): void {
    history.back();
  }

  public showAlert(title: string, content: string): void {
    const dialogId = new Date();
    this._communicationService.setLatestDialogId(dialogId);
    this._dialog.open(DialogComponent, {
      data: {
        title,
        content,
        isConfirmationPopUp: false
      }
    });
  }



  private _handleErrors(error) {
    switch (error.status) {
      default:
        this.showAlert(this.translation.GENERAL_ERRORS.INTERNAL_ERROR_TITLE, this.translation.GENERAL_ERRORS.INTERNAL_ERROR_TEXT);
        break;
    }
  }

  private _subscriptionCallback(): void {
    if (this.subscriptionData && typeof this.subscriptionData) {
      if (this.subscriptionData.notifyComponent === 'app-userNavbar') {
        switch (this.subscriptionData.action) {
         
          case 'toggle' :   
          
            this.toggleNav = this.subscriptionData.data.toggleNav
          break; 
          default:
            break;
        }
      }
    }
  }
}
