import {Component, OnInit} from '@angular/core';
import {CommunicationService} from '../services/communication/communication.service';

import {TranslateService} from '@ngx-translate/core';
import {DialogComponent} from '../components/dialog/dialog.component';
import {MatDialog} from '@angular/material';
import {UtilitiesService} from "../services/utilities.service";
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as moment from 'moment';
import { PermissionService } from '../services/permission/permission.service';


@Component({
  selector: 'app-mediaQueryScreen',
  templateUrl: './mediaQueryScreen.component.html',
  styleUrls: ['./mediaQueryScreen.component.scss']
})
export class MediaQueryScreenComponent implements OnInit {
  public faqs;
  public currentLang;
  public direction;
  public translation;
  public customOptions : OwlOptions;
  public tickets = []; 
  public isVendors; 
  public isUserLoggedIn ; 
  public subscriptionData; 
  public subscription
  constructor(private _communicationService: CommunicationService,
              private _utilitiesService: UtilitiesService,
              private _permissionService : PermissionService,
              private _translate: TranslateService, private _dialog: MatDialog) {
    this.currentLang = this._translate.currentLang;
    this.direction = this.currentLang === 'ar' ? 'rtl' : 'ltr';

    this.isVendors = this._permissionService.checkIsUserVendor();
    this.isUserLoggedIn = this._permissionService.checkUserLoggedIn(); 
    
    this.subscription = this._communicationService.getData().subscribe(response => {
      this.subscriptionData = response;
      this._subscriptionCallback();
    });

  
  }

  public ngOnInit() {
    // this._translate.getTranslation(this._translate.currentLang).subscribe(translation => this.translation = translation,
    //   err => null, () => {
    //     this._utilitiesService.setPageTitle(this.translation.TOP_BAR.FAQs);
        
    //   });
      this.currentLang = this._translate.currentLang;
      this.direction = this.currentLang === 'ar' ? 'rtl' : 'ltr';
      this._translate.getTranslation(this._translate.currentLang).subscribe(translation => {
        console.log("translation events" ,translation)
        this.translation = translation;
       
      });

      
  }

  public back(): void {
    history.back();
  }

  public showAlert(title: string, content: string): void {
    const dialogId = new Date();
    this._communicationService.setLatestDialogId(dialogId);
    this._dialog.open(DialogComponent, {
      data: {
        title,
        content,
        isConfirmationPopUp: false
      }
    });
  }
  public routeToLogin() {
    this._utilitiesService.routeToLogin()
    
  }

  public routeToSignUp() {
    this._utilitiesService.routeTosignUp(); 
  
  }

  public routeToDashboard() {
    this._utilitiesService.routeToDashboard()
  }

  public routeToContact() {
    this._utilitiesService.routeToContact()
  }
  

  public routeToPolicy() {
    this._utilitiesService.routeToPolicy()
  }

  public routeToMyTicket() {
    this._utilitiesService.routeToMyTicket()
  }



  

  private _handleErrors(error) {
    switch (error.status) {
      default:
        this.showAlert(this.translation.GENERAL_ERRORS.INTERNAL_ERROR_TITLE, this.translation.GENERAL_ERRORS.INTERNAL_ERROR_TEXT);
        break;
    }
  }

  private _subscriptionCallback(): void {
    if (this.subscriptionData && typeof this.subscriptionData) {
      if (this.subscriptionData.notifyComponent === 'app-mediaQueryScreen') {
        switch (this.subscriptionData.action) {
      
          case 'refresh':
            this.isVendors = this._permissionService.checkIsUserVendor();
            this.isUserLoggedIn = this._permissionService.checkUserLoggedIn()
          
            break;
          default:
            break;
        }
      }
    }
  }
}
