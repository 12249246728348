import {Component, Inject, Input, Output, EventEmitter} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {CommunicationService} from '../../services/communication/communication.service';
import {TranslateService} from '@ngx-translate/core';

import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { environment } from 'src/environments/environment';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { EventsService } from 'src/app/events/events.service';
import { Subscription, timer } from 'rxjs';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { IOption } from 'src/app/modules/form/select/select.interface';
import { ShowsService } from 'src/app/shows/shows.service';

import { map, takeWhile } from 'rxjs/operators';

import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-paymentStep',
  templateUrl: './paymentStep.component.html',
  styleUrls: ['./paymentStep.component.scss'],
})
export class PaymentStepComponent {

  @Input() public eventData;

  public currentLang;
  public direction;
  public form: FormGroup;
  public translation;
  public customOptions : OwlOptions;

  public isResponsive: boolean;
  public title = 'Contact Us'
  public siteKey: string = environment.captchaSiteKey;
  public crewTypes :string[] = ['actors', 'authors', 'directors'] ; 
  public crews  = {} ; 
  public isLoading : boolean = false; 
  public isVendors : boolean = true;
  public policies = [
    "ATTENDANCE",
    "DRINKS",
    "REFUND",
    "EID_POLICY"
  ]; 

  @Output() public eventEmitter = new EventEmitter();
  @Output() public checkEmitter = new EventEmitter();
  @Output() public backStepEmitter = new EventEmitter(); 
  @Input() public methods;
  @Input() seconds = 600;

  constructor(
              private _formBuilder: FormBuilder,
              private _utilitiesService: UtilitiesService,
              private _showsService: ShowsService, 
              private _communicationService: CommunicationService,
              private _permissionService: PermissionService,
              private _translate: TranslateService, private _dialog: MatDialog) {


    this.isVendors = this._permissionService.checkIsUserVendor();
    console.log( "isVendors",this.isVendors)
 
  }







  public ngOnInit() {

    this.currentLang = this._translate.currentLang;
    this.direction = this.currentLang === 'ar' ? 'rtl' : 'ltr';
    this._translate.getTranslation(this._translate.currentLang).subscribe(translation => {
      console.log("translation events" ,translation)
      this.translation = translation;
    });
  

    this._initializeForm()

    console.log(this.methods)
  }

  public openSidebar() {
    console.log("testmhoba")
  }



  timeRemaining$ = timer(0, 1000).pipe(
    map(n => (this.seconds - n) * 1000),
    takeWhile(n => n >= 0),
  );

  public selectMethod(methodId) {
    this.methods = this.methods.map(item => {
      if (methodId == item.PaymentMethodId)
        item.selected = true; 
      else 
      item.selected = false ; 
    return item; 
    })
    this.eventEmitter.emit(methodId)
  }

  public submitForm() {
    console.log('submitting..')

    console.log("this.form", this.form)
  }

  public check(val) {
    console.log("check",val)
    this.checkEmitter.emit(val.value)
  }

  private _initializeForm(): void {
    this.form = this._formBuilder.group({
    terms : [false, Validators.required], 
    clientName : ['',Validators.required], 
    clientPhoneCountryCode : ['KW', Validators.required], 
    clientPhoneLine : ['',Validators.required], 
    clientEmail : ['',Validators.required], 

    clientPhone : [''],
    });
   console.log("this.form",this.form)
  }

}
