export const variables = {
    config: {
      maxVideoSize: 18874368 // In Bytes - 18MB
    },
    style: {
      dimensions: {
        starSizeResponsive: '12px',
        starSize: '18px'
      },
      colors: {
        activeStar: '#facb00',
        inactiveStar: '#c9c0bf'
      }
    },
    storageVariables: {
      AccessToken: 'AccessToken',
      RefreshToken: 'RefreshToken',
      RefreshTokenHeader: 'RefreshTokenHeader',
      TokenExpiry: 'TokenExpiry',
      Language: 'language',
      ID: '_id',
      Name: 'name',
      Image: 'image',
      IsEmail: 'isEmail',
      Email: 'email',
      PhoneNumber: 'phoneNumber',
      Number: 'number',
      PhoneCode: 'phoneCode',
      Wallet: 'wallet',
      allowedToRoute: 'allowedToRoute', 
      profiles : 'profiles', 
      vendor : "vendor", 
      access : "access", 
      masterAccess : "masterAccess"
    },
    AuthenticationUri: {
      logout: 'authentication/logout',
      refreshToken: 'authentication/refreshToken',
      userSignUp: 'users/',
      userCheckOtp: 'users/checkOtp',
      resendOtp: 'users/resendOtp',
      resetPasswordCheckToken: 'users/checkToken',
      resetPasswordResendOTP: 'users/resendPassOtp',
      signIn: 'v2/authentication/login',
      registerNotification: 'users/register',
      forgotPassword: 'users/forgot',
      changePassword: 'users/changePassword'
    },
    emailRegex: '[A-Za-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?', // tslint:disable-line:max-line-length
    settings: {
      getUserProfile: 'api/userProfile',
      saveUserProfile: 'api/userProfile'
    },
    dashboard: {
      getDashboard: 'play',
      verifyPurchase: 'ticket/validate'
    },
    faq: {
      getFaqs: 'faq'
    },
    plays: {
      getPlays: 'play/search',
      getCountriesByShow: 'play/show/countries'
    },
    staticPages: {
      getStaticPages: 'lov'
    },
    profile: {
      getProfile: 'users/profile',
      saveProfile: 'users/profile',
      uploadImage: 'users/image',
      deleteImage: 'users/image',
      getEvents: 'ticket'
    },
    receipt: {
      getReceipt: 'ticket/receipt',
      refundTickets: 'ticket/refund'
    },
    showDetails: {
      getShowByID: 'play/show',
      getShowByIDAndCountry: 'play/countries',
      getZones: 'zone/play',
      getSeats: 'map/zone',
      bookSeats: 'ticket',
      paymentMethods: 'ticket/paymentMethods'
    },
    contactUs: {
      submit: 'contact'
    },
    events : {
      getEvents : 'cms/show', 
      createEvents : 'cms/show', 
      getCrews : 'cms/crew', 
      getEventDetails : 'cms/show/getShowDetails', 
      getVendorZones : 'cms/zone/vendorZones', 
      editEvents : 'cms/show',
      paymentMethods : 'cms/tickets/paymentMethods', 
      
    }, 
    customerApis : {
      getCategories : 'shows', 
      getShowDetails : 'shows/:showID',
      getCarouselData : 'carouselShows', 
      tickets : 'ticket/all'
      
    }, 
    shows : {
      getShows : 'cms/play', 
      getShowsList : 'cms/play/listByShow', 
      postShows : 'cms/play', 
      getTheater : 'cms/theater/list', 
      editShow : 'cms/play', 
      activateShow : 'cms/play/showInWeb', 
      deactivateShow : 'cms/play/hideInWeb', 
      deleteShow : 'cms/play'
    },
    employees : {
      getEmployees : 'cmsUser/vendors/:vendorID/shows/:showID/users', 
      getPermissions : 'cmsUser/vendors/:vendorID/usersPermissions',
      createEmployee : 'cmsUser/vendors/:vendorID/shows/:showID/users',
      editEmployee : 'cmsUser/vendors/:vendorID/shows/:showID/users/:userID',
      deleteEmployee : 'cmsUser/vendors/:vendorID/shows/:showID/users/:userID', 
      getEmployeeAccess : 'cmsUser/vendors/:vendorID/shows/:showID/users/:userID/access', 

    },
    reservations : {
      getReservations : 'cms/shows/:showID/tickets', 
      enterReservation : 'ticket/submitVendorPassedTickets',
      cancelReservation : 'cms/tickets/refund', 
      sendEmail : 'cms/tickets/:ticketID/sendViaEmail', 
      downloadTicket : 'cms/tickets/:ticketID/print', 
      exchange : 'cms/tickets/exchange',
      bookReservedTicket : 'cms/tickets/bookReserved'

    },
    sellingTickets : {
      getMap : 'cms/map/all', 
      bookSeat : 'cms/tickets/addTicket'
    },
    zoneManage : { 
      postZone : "cms/zone", 
      getMapBySeatPlanId : "cms/plan/vendorMatrix",
      updateSeatZone : 'cms/plan/vendorMatrix',
      getZonesByPlay:'zone/play'
    },
    payments : {
      getPaymentsInfo : 'cms/shows/:showID/payments', 
      getPaymentsReport : 'cms/shows/:showID/tickets?isExport=true&isExcel=true', 
      getDailyPayments : 'cms/tickets/shows/:showID/dailyPayments', 
      enterShow : 'ticket/submitVendorPassedTickets'
    }, 
    statistics : {
      getTicketsStatistics : 'cms/zone/shows/:showID/statistics', 
      getAttendance : "cms/zone/shows/:showID/attendance/statistics", 
      getReservationsStat : "cms/tickets/shows/:showID/statistics"
    }, 
    socialMediaLink: {
      insta: 'test',
      facebook: 'test',
      mail: 'test',
      twitter: 'test'
    },
    routes: {
      dashboard: '',
      authentication: 'a',
      signUpAuth: 'sign-up',
      signInAuth: 'sign-in',
      forgotPasswordAuth: 'forgot-password',
      resetPasswordAuth: 'reset-password',
      signUp: 'a/sign-up',
      signIn: 'a/sign-in',
      forgotPassword: 'a/forgot-password',
      profile: 'profile',
      show: 'show',
      showStepOne: 'show/details',
      showStepTwo: 'show/zones',
      showStepThree: 'show/tickets',
      showStepFour: 'show/payment',
      dashboard_full: 'dashboard',
      contactUs: 'contactUs',
      faqs: 'faqs',
      terms: 'terms',
      aboutUs: 'aboutUs',
      howItWorks: 'howItWorks',
      plays: 'shows',
      Home : 'homePage',
      FAQs : 'about',
      contactInfo : 'contactinfo', 
      showDetails : 'showDetails', 
      buyTicket : 'buyTicket', 
      policy : "privacyPolicy", 
      tickets : 'mytickets'
    },
    vendorsRoutes : {
      events : 'events', 
      eventDashboard : 'eventDashboard', 
      eventDetails : 'eventDetails', 
      shows : "shows", 
      employees : "employees",
      reservations : "reservations", 
      payments : "payments", 
      checkIn : 'checkin', 
      statistics : 'statistics', 
      sellingTickets : 'sellingTickets', 
      eventPage : 'eventPage', 
      zoneManagement : 'zoneManagement', 
      showMap : 'showMap',
    
    }, 
    vendorsRoutesAccess : {
      events : '', 
      eventDashboard : '', 
      eventDetails : 'edit_show', 
      shows : "edit_show", 
      employees : "manage_staff",
      reservations : "manage_tickets", 
      payments : "view_payments", 
      checkIn : 'scan_tickets', 
      statistics : 'view_show_statisics', 
      sellingTickets : 'buy_tickets', 
      eventPage : 'buy_tickets', 
      zoneManagement : 'edit_show', 
      showMap : 'edit_show'
    }, 
    googleAnalytics: {
      dashboard_screen: 'Dashboard',
      register_screen: 'Sign Up',
      login_screen: 'Login',
      forgot_password_screen: 'Forgot Password',
      show_details_screen: 'Show Details',
      profile_screen: 'Profile',
      plays_screen: 'Shows',
      aboutUs_screen: 'About Us',
      howItWorks_screen: 'How it Works',
      terms_screen: 'Privacy Policy & Terms of Use',
      faqs_screen: 'FAQs',
      contactUs_screen: 'Contact Us'
    }
  }
;
