import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {CommunicationService} from '../../services/communication/communication.service';
import {TranslateService} from '@ngx-translate/core';

import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import { environment } from 'src/environments/environment';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { EventsService } from 'src/app/events/events.service';
import { Subscription } from 'rxjs';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { IOption } from 'src/app/modules/form/select/select.interface';
import { ShowsService } from 'src/app/shows/shows.service';

@Component({
  selector: 'app-reservationsShows',
  templateUrl: './reservationsShows.component.html',
  styleUrls: ['./reservationsShows.component.scss'],
})
export class ReservationsShowsDialogComponent {

  public currentLang;
  public direction;
  public form: FormGroup;
  public translation;
  // public emailRegex = variables.emailRegex;
  public emailPatternErrorMessage;
  public validCaptcha: boolean;
  // public countries = countries;
  public isResponsive: boolean;
  public title = 'Contact Us'
  public siteKey: string = environment.captchaSiteKey;
  public crewTypes :string[] = ['actors', 'authors', 'directors'] ; 
  public crews  = {} ; 
  public isLoading : boolean = false; 
  public isVendors : boolean = true;
  

  constructor(@Inject(MAT_DIALOG_DATA) public data, 
              private _formBuilder: FormBuilder,
              private _utilitiesService: UtilitiesService,
              private _showsService: ShowsService, 
              private _communicationService: CommunicationService,
              private _permissionService: PermissionService,
              private _translate: TranslateService, private _dialog: MatDialog) {


    this.isVendors = this._permissionService.checkIsUserVendor();
    console.log( "isVendors",this.isVendors)
 
  }


  public e2a = s => s.replace(/\d/g, d => '٠١٢٣٤٥٦٧٨٩'[d])

  public ngOnInit() {

    this.currentLang = this._translate.currentLang;
    this.direction = this.currentLang === 'ar' ? 'rtl' : 'ltr';
    this._translate.getTranslation(this._translate.currentLang).subscribe(translation => {
      console.log("translation events" ,translation)
      this.translation = translation;
    });
    
    console.log("from reservation shows", this.data)

  }

  public onSelectShow (showId) {
    if (this.data.reservations)
    {
      this._communicationService.notifyComponent('app-reservations','getReservationsShow',{
        showId
      }); 
    }
    if (this.data.payments)
    {
      this._communicationService.notifyComponent('app-payments','getPaymentsShow',{
        showId
      });
    }
   if (this.data.statistics)
   {
    this._communicationService.notifyComponent('app-statistics','getStatisticsShow',{
      showId
    });
   }
    
    if (this.data.eventPage)
    {
      this._communicationService.notifyComponent('app-event-page','getStatisticsShow',{
        showId
      });
    }
    
    if (this.data.exchange)
    {
      this._communicationService.notifyComponent('app-exchange-dialog','refresh',{
        showId
      });
    }
    if (this.data.appSeatChart)
    {
      this._communicationService.notifyComponent('app-sellingTickets','refresh',{
        showId
      });
    }
    if (this.data.appShowDetails)
    {
      this._communicationService.notifyComponent('app-showDetails','selectedShow',{
        showId
      });
    }

    // this._dialog.closeAll();
  }

 

}
